import React, { useEffect, useState } from "react";
import {
  getCategoriesList,
  getCategoriesListByCustomer,
  deleteSAML,
  getgraphapi,
  getsaml,
} from "./requests";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../utils";
import { PageTitle } from "../../../../sdb/layout/core";

const API_URL = process.env.REACT_APP_API_URL;

export function MicrosoftSAML() {
  const history = useNavigate();
  const { currentUser } = useAuth();
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [sortBy, setSortBy] = useState(null); // State to keep track of the column to sort by
  const [sortDirection, setSortDirection] = useState("asc"); // State to keep track of the sort direction
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = categoryList.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");
  const [activeView, setActiveView] = useState("Information");

  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterCategoryList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            (x.customer && x.customer.toLowerCase().includes(iText)) ||
            (x.customer_id && x.customer_id.toLowerCase().includes(iText))
        );
      }
      setCategoryList(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const getCategory = async () => {
    try {
      const { data } = await getsaml();
      if (data) {
        setCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setFilterCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteRecord = async (item: any, rowIndex: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSAML(item._id)
          .then((r) => {
            let newRecords = [...categoryList];
            let uRecords = newRecords.filter(
              (ditem: any) => ditem._id != item._id
            );
            setCategoryList(uRecords);
            setFilterCategoryList(uRecords);
            Swal.fire({
              icon: "success",
              title: "Mircosoft SAML",
              text: "Mircosoft SAML has been deleted successfully!",
            });
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error.response.data.detail, "error");
          });
      }
    });
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleSort = (column) => {
    if (sortBy === column) {
      // If already sorting by this column, reverse sort direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If sorting by a new column, set it as the sorting column and default to ascending
      setSortBy(column);
      setSortDirection("asc");
    }
  };

  const sortedDataSet = sortBy
    ? dataSet.sort((a, b) => {
        const aValue = a[sortBy];
        const bValue = b[sortBy];
        if (aValue < bValue) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      })
    : dataSet;

  return (
    <div>
      
      <PageTitle
        breadcrumbs={[
          {

            title: (
              <span
                className="breadcrumb-title"
                style={{
                  display: 'inline-block',
                  color: 'inherit',
                  textDecoration: 'none', 
                  backgroundColor: 'transparent', 
                  borderRadius: '3px', 
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.textDecoration = 'underline';
                  e.currentTarget.style.backgroundColor = '#f0f8ff'; 
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.color = 'inherit'; 
                  e.currentTarget.style.textDecoration = 'none'; 
                  e.currentTarget.style.backgroundColor = 'transparent';
                }}
              >
            Integrations
              </span>
            ),  
            path: "/integrations",
            isSeparator: false,
            isActive: false,
          },
        ]}
      >
        - Microsoft-SAML
      </PageTitle>

      <div className="card card-flush my-5">
        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1">
              <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              <input
                type="text"
                value={searchText}
                data-kt-ecommerce-product-filter="search"
                className="form-control form-control-solid w-250px ps-12"
                placeholder="Search Microsoft SAML "
                onChange={(e) => {
                  const value = e.target.value;
                  setSearchText(value);
                }}
              />
            </div>
          </div>

          <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
            <a
              onClick={(e) => {
                e.preventDefault();
                history("/addmicrosoftsaml");
              }}
              className="btn btn-primary"
            >
              Add Microsoft SAML
            </a>
          </div>
        </div>

        <div className="card-body pt-0" style={{ overflowX: "auto" }}>
          <table
            className="table align-middle table-row-dashed fs-6 gy-5"
            id="kt_ecommerce_products_table"
          >
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-150px" onClick={() => handleSort("customer")}>
                  TENANT NAME {sortBy === "customer" && <span>{sortDirection === "asc" ? "▲" : "▼"}</span>}
                </th>
                <th className="min-w-50px" onClick={() => handleSort("customer_id")}>
                  TENANT ID {sortBy === "customer_id" && <span>{sortDirection === "asc" ? "▲" : "▼"}</span>}
                </th>
                <th className="min-w-100px">STATUS</th>
                <th className="text-end min-w-70px">Actions</th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              {sortedDataSet.map((item: any, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <div className="d-flex">{item.customer}</div>
                    </td>
                    <td>
                      <div className="d-flex">{item.customer_id}</div>
                    </td>
                    <td className="pe-0">
                      <div
                        className={
                          item.is_active
                            ? "badge badge-light-success fw-bolder"
                            : "badge badge-light-danger fw-bolder"
                        }
                      >
                        {item.is_active ? "Active" : "Inactive"}
                      </div>
                    </td>
                    <td className="text-end">
                      <a
                        className="btn btn-light btn-active-light-primary btn-sm"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                        id="dropdownMenuButton1"
                      >
                        Actions
                        <i className="ki-duotone ki-down fs-5 ms-1"></i>
                      </a>

                      <div
                        className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                        data-kt-menu="flase"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <div className="menu-item px-3">
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              history("/addmicrosoftsaml", {
                                state: item,
                              });
                            }}
                            className="menu-link px-3"
                          >
                            Edit
                          </a>
                        </div>

                        <div className="menu-item px-3">
                          <a
                            onClick={() => deleteRecord(item, i)}
                            className="menu-link px-3"
                            data-kt-ecommerce-product-filter="delete_row"
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              {categoryList.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Records
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row mt-2">
            <div className="col-1">
              <select
                name="records_per_age"
                className="form-select form-select-lg form-select-solid w-auto"
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  setItemsPerPage(value);
                  setTotalPages(Math.ceil(categoryList.length / value));
                }}
                value={itemsPerPage}
              >
                {new Array(5).fill(null).map((i, index) => {
                  const value = 10 * (index + 1);
                  return (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-11">
              {
                <ResponsivePagination
                  total={totalPages}
                  current={currentPage}
                  onPageChange={(page) => handlePageChange(page)}
                />
              }
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}
