/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { Auth } from "../modules/public/pantry/auth";
import { App } from "../App";
import { lazy, Suspense } from "react";
import { getCSSVariableValue } from "../../sdb/assets/ts/_utils";
import { WithChildren } from "../../sdb/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import { AuthLoading } from "../modules/auth/AuthLoading";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
const AppRoutes: FC = () => {
  const { currentUser } = useAuth();
  const PublicPage = lazy(() => import("../modules/public/landing"));
  const PantryPublicPage = lazy(
    () => import("../modules/public/pantry/landing")
  );
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          {currentUser ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="auth-landing/" element={<AuthLoading />} />
              <Route
                path="vms/*"
                element={
                  <SuspensedView>
                    <PublicPage />
                  </SuspensedView>
                }
              />
              {/*  <Route path="/pms" element={<Auth />} /> */}
              <Route
                path="pms/*"
                element={
                  <SuspensedView>
                    <PantryPublicPage />
                  </SuspensedView>
                }
              />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
