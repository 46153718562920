import ImageMapper from "react-img-mapper";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  getZonesByFloorId,
  getDeviceByZone,
} from "../../pod/components/requests";

import { getIaqDataByFloorId, getIaqDataByZoneId } from "./request";

const API_URL = process.env.REACT_APP_API_URL;
const DeviceMapView = forwardRef((props, ref) => {
  const [userLayout, setUserLayout] = useState({
    name: "userLayout",
    areas: [] as any[], // Initialize as empty array
  });
  
  const [floorInfo, setFloorInfo]: any = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [hoveredArea, setHoveredArea]: any = useState(null);
  const [msg, setMsg]: any = useState(null);
  const [moveMsg, setMoveMsg]: any = useState(null);
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const getDataList = async (floor_Id: any) => {
    localStorage.setItem("floorID", floor_Id);
    try {
      const { data } = await getIaqDataByFloorId(floor_Id);
      console.log("Data fetched:", data); // Log fetched data
      if (data) {
        updateFloorInfoWithAqi(data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const calculatePreFillColor = (aqi) => {
    // if (aqi <= 50) {
    //   return "green";
    // } else if (aqi <= 110) {
    //   return "yellow";
    // } else {
    //   return "red";
    // }
    if (aqi <= 50) {
      return "green";
    } else if (aqi >= 50 && aqi <= 100) {
      return "orange";
    } else if (aqi >= 150 && aqi <= 200) {
      return "red";
    } else if (aqi >= 200 && aqi <= 300) {
      return "blue";
    } else {
      return "pink";
    }

  };
  
  const updateFloorInfoWithAqi = (data: any[]) => {
    const updatedAreas = userLayout.areas.map((area: any) => {
      if (area && area.shape === "circle" && area.coords && area.coords.length === 3) {
        const deviceData = data.find((item: any) => item.name === area.namee);
        console.log("Area name:", area.name);
        console.log("Device data for area", area.name, ":", deviceData);
        if (deviceData && deviceData.meta && deviceData.meta.aqi !== undefined) {
          console.log("AQI:", deviceData.meta.aqi);
          const aqi = deviceData.meta.aqi;
          const preFillColor = calculatePreFillColor(aqi);
          
          return {
            ...area,
            preFillColor: preFillColor
          };
        }
      }
      return area;
    });
  
    setUserLayout((prevLayout: any) => ({
      ...prevLayout,
      areas: updatedAreas
    }));
  };
  
  
  
  
    

  

  useEffect(() => {
    if (floorInfo) {
      getDataList(floorInfo._id);
      
    }
  }, [floorInfo]);

  const updateFloorInfo = (floor) => {
    if (floor) {
      floor["path"] = `${API_URL}/${floor.floor_map}`;
      setFloorInfo(floor);
      getZoneList(floor).then((r: any) => {
        let items = r.filter((x) => x != null);
        let deviceList = [].concat.apply([], items);
        let zoneMapList: any = [];
        deviceList.forEach((item: any) => {
          const iZone = item.zone;
          const iZoneExist = zoneMapList.find((x) => x.zoneId === iZone._id);
          if (iZone && iZone.coordinates) {
            iZone.coordinates.forEach((item: any) => {
              if (item.path_type && !iZoneExist) {
                zoneMapList.push({
                  coords: item.coords ? item.coords : [],
                  fillColor: "rgba(0, 0, 255, 0.2)",
                  name: "P1",
                  preFillColor: "rgba(0, 0, 255, 0.15)",
                  shape: item.path_type,
                  zoneId: iZone._id,
                });
              }
            });
          }
          if (item.coordinates) {
            const cord = item.coordinates[0];
            zoneMapList.push({
              name: item.name,
              shape: "circle",
              coords: [cord.x, cord.y, 2],
              preFillColor: "red",
              lineWidth: 20,
            });
          }
        });
        setUserLayout({
          name: "userLayout",
          areas: zoneMapList,
        });


        
        setShowMap(true);
      });
    } else {
      setFloorInfo(null);
      setShowMap(true);
    }
  };
  const getZoneList = (floor) => {
    return new Promise((resolve, reject) => {
      getZonesByFloorId(floor._id)
        .then((r) => {
          let zoneList = r.data;
          let promiseList: any = [];
          zoneList.forEach((zone) => {
            promiseList.push(getDeviceListByZoneId(zone));
          });
          Promise.all(promiseList).then((values) => {
            resolve(values);
          });
        })
        .catch((r) => {
          resolve([]);
        });
    });
  };
  const getDeviceListByZoneId = (zone) => {
    return new Promise((resolve, reject) => {
      getDeviceByZone(zone._id)
        .then((r) => {
          resolve(r.data);
        })
        .catch((e) => {
          resolve(null);
        });
    });
  };



  const [selectedZone, setSelectedZone] = useState(null);


  const updateZoneColor = (zoneId: string, color: string) => {
    setUserLayout((prevLayout) => ({
      ...prevLayout,
      areas: prevLayout.areas.map((area: any) => {
        if (area.zoneId === zoneId) {
          return { ...area, fillColor: color };
        }
        return area;
      })
    }));
  };
  
  const clicked = (area) => {
    setHoveredArea(null); // Clear hovered area
    setMsg(`You clicked on ${area.shape} at coords ${JSON.stringify(area.coords)} !`);
    
    // Update selected zone and its color
    setSelectedZone(area.zoneId);
    updateZoneColor(area.zoneId, "yellow"); // Change to desired color when clicked
  };
  const clickedOutside = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMsg(`You clicked on the image at coords ${JSON.stringify(coords)} !`);
  };
  const moveOnImage = (evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(`You moved on the image at coords ${JSON.stringify(coords)} !`);
  };
  const enterArea = (area) => {
    setHoveredArea(area);
    setMsg(
      `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    );
  };
  const leaveArea = (area) => {
    setHoveredArea(null);
    setMsg(
      `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`
    );
  };
  const moveOnArea = (area, evt) => {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    setMoveMsg(
      `You moved on ${area.shape} ${area.name} at coords ${JSON.stringify(
        coords
      )} !`
    );
  };
  const getTipPosition = (area) => {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  };
  useImperativeHandle(ref, () => ({
    updateFloorInfo: updateFloorInfo,
  }));


  const handleZoneSelection = (selectedZoneId) => {
    setUserLayout((prevLayout) => ({
      ...prevLayout,
      areas: prevLayout.areas.map((area) => {
        if (area.zoneId === selectedZoneId) {
          return { ...area, fillColor: "yellow" }; // Change to desired color
        }
        return area;
      })
    }));
  };
  
  return (
    <div
      className="row mb-10"
      style={{ display: "flex", justifyContent: "center" }}
      id="zone"
    >
      <div className="col-6">
        {/* <div className="card">
            <div className="card-body"> */}
        {floorInfo && showMap && (


          <div className="row">
            <div className="col-12">
            <select onChange={(e) => handleZoneSelection(e.target.value)}>
  <option value="">Select Zone</option>
  {userLayout.areas.map((area, index) => (
    <option key={index} value={area.zoneId}>{area.name}</option>
  ))}
</select>



              <div className="image-mappers">
                <ImageMapper
                  src={floorInfo.path}
                  width={500}
                  map={userLayout}
                  onLoad={() => {}}
                  onClick={(area) => clicked(area)}
                  onMouseEnter={(area) => enterArea(area)}
                  onMouseLeave={(area) => leaveArea(area)}
                  onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
                  onImageClick={(evt) => clickedOutside(evt)}
                  onImageMouseMove={(evt) => moveOnImage(evt)}
                  lineWidth={2}
                  strokeColor={"rgba(255, 255, 255, 0.1)"}
                />
                {hoveredArea && (
                  <span
                    className="tooltip"
                    style={{ ...getTipPosition(hoveredArea) }}
                  >
                    {hoveredArea && hoveredArea.name}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
        {/* </div>
          </div> */}
      </div>
    </div>
  );
});
export { DeviceMapView };
