import { AuthModel } from "./_models";

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v";
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
    localStorage.removeItem("floorID");
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};
const enableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "flex");
  }
};

const disableSplashScreen = () => {
  const splashScreen = document.getElementById("splash-screen");
  if (splashScreen) {
    splashScreen.style.setProperty("display", "none");
  }
};
export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = "application/json";
  axios.interceptors.request.use(
    (config: any) => {
      const auth = getAuth();
      /*  if (!config.url.includes("email")) {
        document.body.classList.add("custom-loader");
      } */
      if (
        !config.url.includes("email") &&
        !(
          config.url.includes("customer") ||
          config.url.includes("location") ||
          config.url.includes("host") ||
          config.url.includes("skip") ||
          config.url.includes("limit")
        ) &&
        !(config.url.includes("orders") && config.url.includes("floor")) &&
        !(
          (config.url.includes("data") && config.url.includes("floor")) ||
          config.url.includes("floors")
        ) &&
        !(
          (config.url.includes("data") && config.url.includes("zone")) ||
          config.url.includes("zones")
        )
      ) {
        document.body.classList.add("custom-loader");
      }
      if (auth && auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`;
      }

      return config;
    },
    (err: any) => {
      document.body.classList.remove("custom-loader");
      Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    function (response) {
      document.body.classList.remove("custom-loader");
      return response;
    },
    function (error) {
      document.body.classList.remove("custom-loader");
      return Promise.reject(error);
    }
  );
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY };
