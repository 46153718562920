import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
// location methods
export function getEndpoints() {
  return axios.get<any>(`${API_URL}/endpoints/`);
}
