import React, { useEffect, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { useListView } from "../../apps/user-management/users-list/core/ListViewProvider";
import Select from "react-select";
import { FeedbackForm } from "./components/FeedbackForm";
import {
  getOrdersByFloorId,
  getOrdersByZoneId,
  getStatusList,
  updateOrderStatus,
} from "./requests";
import { getFloorList } from "../../apps/pantry-management/requests";
import { useNavigate, useLocation } from "react-router-dom";
import { useDebouncedCallback } from "../../../utils";
import moment from 'moment';
import "./orderlist.css";

export function Pendingorders(props: any) {
  const history = useNavigate();
  const { isAllSelected, onSelectAll } = useListView();
  const { selected, onSelect } = useListView();
  const isSelected = false;
  const [orderDataList, setOrderDataList] = useState<any[]>([]);
  const [filterOrderDataList, setFilterOrderDataList] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [searchText, setSearchText] = useState("");
  // const [sortColumn, setSortColumn] = useState<string | null>(null);
  // const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const location = useLocation();
  const itemInfo: any = location.state;

  const dataSet = orderDataList.slice(startIndex, endIndex);
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterOrderDataList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            x.order_id.toLowerCase().includes(iText) ||
            (x.floor &&
              x.floor.name &&
              x.floor.name.toLowerCase().includes(iText)) ||
            (x.zone && x.zone.name && x.zone.name.toLowerCase().includes(iText))
        );
      }
      setOrderDataList(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const [orderStatusList, setOrderStatusList] = useState([]);
  const [viewModal, setModalData] = useState(false);
  const [viewOrderModal, setOrderModalData] = useState(false);
  const [orderDetails, setOrderDetails]: any = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [floorList, setFloorList] = useState([]);
  const [floorId, setFloorId] = useState(null);
  const [floorID, setFloorID] = useState<string | null>(
    localStorage.getItem("floorID")
  );

  const getOrders = async (floor_Id: string) => {
    localStorage.setItem("floorID", floor_Id);

    try {
      const { data } = await getOrdersByFloorId(floor_Id);
      if (data) {
        setOrderDataList(data);
        setFilterOrderDataList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrdersByZone = async (zoneId: string) => {
    try {
      const { data } = await getOrdersByZoneId(zoneId);
      if (data) {
        setOrderDataList(data);
        setFilterOrderDataList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFloors = async () => {
    try {
      const { data } = await getFloorList();
      if (data) {
        const formattedFloorList = data.map((floor) => ({
          value: floor._id,
          label: ` ${floor.building.name}|${floor.name}`,
        }));
        setFloorList(formattedFloorList);
        if (itemInfo) {
          const selectedFloor = formattedFloorList.find((d) =>
            itemInfo.floor_id.includes(d.value)
          );
          setFloorId(selectedFloor ? selectedFloor.value : null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getOrderStatusList = async () => {
    try {
      const { data } = await getStatusList();
      if (data) {
        setOrderStatusList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateStatus = async (item: any, status: string, itemIndex: number) => {
    try {
      const { data } = await updateOrderStatus(item.order_id, {
        order_status: status,
      });
      if (data) {
        let fId: any = floorID;
        getOrders(fId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props.viewFrom) {
      getFloors();
      getOrderStatusList();
    } else {
      const itemId = window.localStorage.getItem("zone_id");
      if (!itemId) {
        history("/pms/");
      } else {
        const zoneId: string = itemId ? itemId : "";
        getOrdersByZone(zoneId);
      }
    }
  }, []);

  useEffect(() => {
    if (floorID) {
      const intervalId = setInterval(() => {
        getOrders(floorID);
      }, 30000);

      // Clear the interval when the component unmounts or when the floorID changes
      return () => clearInterval(intervalId);
    }
  }, [floorID]);

  useEffect(() => {
    setStartIndex((currentPage ? currentPage - 1 : 0) * itemsPerPage);
    setEndIndex(startIndex + itemsPerPage);
  }, [currentPage, itemsPerPage, startIndex]);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column) => {
    // console.log("Sorting column:", column);
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    // console.log("New sort direction:", newDirection);
    setSortColumn(column);
    setSortDirection(newDirection);
    sortData(column, newDirection);
  };

  const sortData = (column, direction) => {
    const sortedData = [...orderDataList].sort((a, b) => {
      // Extracting values to compare
      const valueA = getColumnValue(a, column);
      const valueB = getColumnValue(b, column);

      if (typeof valueA === "string" && typeof valueB === "string") {
        // Case-insensitive string comparison
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        // Numeric comparison
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }
      // Add more comparisons for other data types if needed
      return 0;
    });

    // Set the sorted data to the state
    setOrderDataList(sortedData);
  };

  const getColumnValue = (dataItem, column) => {
    // Handle nested properties like "visitor_profile.full_name"
    const properties = column.split(".");
    let value = dataItem;
    for (let property of properties) {
      value = value[property];
      if (value === undefined || value === null) return "";
    }
    return value;
  };

  return (
    <>
      {!props.viewFrom && (
        <div className="row">
          <div className="col-12">
            <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
              <div
                id="kt_app_toolbar_container"
                className=" d-flex flex-stack jusitify-between w-100"
              >
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                  <h2 className="fw-bolder mt-5 mb-2 page-heading d-flex text-dark flex-column justify-content-center">
                    Pantry Dashboard{/*  - Floor Name -    Name */}
                  </h2>

                  <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                    <li className="breadcrumb-item text-muted">
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          history("/pms/home");
                        }}
                        className="text-muted text-hover-primary"
                      >
                        Pantry Dashboard
                      </a>
                    </li>
                    <li className="breadcrumb-item">
                      <span className="bullet bg-gray-400 w-5px h-2px"></span>
                    </li>
                    <li className="breadcrumb-item text-muted">Track Orders</li>
                  </ul>
                </div>
                <div className="d-flex align-items-center gap-2 gap-lg-3">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history("/pms/home");
                    }}
                    className="btn btn-sm fw-bold btn-primary"
                  >
                    New Order
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row my-5">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header border-0 pt-6">
              <div className="card-title">
                <div className="d-flex align-items-center position-relative my-1">
                  <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </i>
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control form-control-solid w-250px ps-14"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchText(value);
                    }}
                  />
                </div>
              </div>
              {props.viewFrom && (
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      width: "280px",
                      zIndex: "999",
                    }}
                  >
                    <Select
                      name="is_active"
                      options={floorList}
                      className="basic-multi-select floor"
                      placeholder="Select Floor"
                      classNamePrefix="select"
                   
                      onChange={(selectedOption: any) => {
                        if (selectedOption) {
                          const selectedFloorId = selectedOption.value; // Access the value property
                          setFloorID(selectedFloorId);
                          getOrders(selectedFloorId);
                        } else {
                          setFloorID(null);
                          setOrderDataList([]);
                          setFilterOrderDataList([]);
                          setTotalPages(0);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="card-body">
              <div className="table-wrapper">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      {/* <th>
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            data-kt-check={isAllSelected}
                            data-kt-check-target="#kt_table_users .form-check-input"
                            checked={isAllSelected}
                            onChange={onSelectAll}
                          />
                        </div>
                      </th> */}
                      {/* <th>Visiter Id</th> */}
                      <th
                        className="min-w-110px"
                        onClick={() => handleSort("order_id")}
                      >
                        Order ID
                      </th>
                      <th
                        className="min-w-110px"
                        onClick={() => handleSort("floor.name")}
                      >
                        Floor Name
                      </th>
                      <th
                        className="min-w-110px"
                        onClick={() => handleSort("zone.name")}
                      >
                        Zone Name
                      </th>
                      <th
                        className="min-w-110px"
                        onClick={() => handleSort("created_at")}
                      >
                        Date and Time of Order
                      </th>
                      <th
                        className="min-w-110px"
                        onClick={() => handleSort("order_status")}
                      >
                        Order Status
                      </th>

                      {/* <th className="min-w-90px">Order Delivered On</th> */}
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 fw-bold">
                    {dataSet
                      .filter(
                        (item: any) =>
                          item.order_status.toLowerCase() ===
                            "order accepted" ||
                          item.order_status.toLowerCase() ===
                            "out for delivery" ||
                          item.order_status.toLowerCase() === "Delivered"
                      )
                      .map((item: any, i) => {
                        return (
                          <tr role="row" key={i}>
                            {/* <td>
                            <div className="form-check form-check-custom form-check-solid">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                data-kt-check={isSelected}
                                data-kt-check-target="#kt_table_users .form-check-input"
                                checked={isSelected}
                                onChange={() => onSelect(10)}
                              />
                            </div>
                          </td> */}
                            <td>{item?.order_id}</td>
                            <td>{item?.floor?.name}</td>
                            <td>{item?.zone?.name}</td>
                            <td>
  {item.order_at
    ? moment(item.order_at).format("DD/MM/YYYY")
    : ""}
  |
  {item.order_at
    ? moment(item.order_at).format("HH:mm:ss") 
    : ""}
</td>

                            <td>
                              <div
                                className={`${
                                  props.viewFrom &&
                                  item.order_status != "Delivered" &&
                                  item.order_status != "Order Cancelled"
                                    ? "dropdown"
                                    : ""
                                }`}
                              >
                                <div
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  style={{
                                    backgroundColor:
                                      item.meta.status_color_code,
                                  }}
                                  aria-expanded="false"
                                  className={`${
                                    props.viewFrom &&
                                    item.order_status != "Delivered" &&
                                    item.order_status != "Order Cancelled"
                                      ? "dropdown-toggle"
                                      : ""
                                  } badge fw-bolder ${
                                    item.order_status === "preparing" ||
                                    item.order_status === "pending"
                                      ? "badge-light-warning"
                                      : ""
                                  } ${
                                    item.order_status === "approved" ||
                                    item.order_status === "delivered"
                                      ? "badge-light-success"
                                      : ""
                                  } ${
                                    item.order_status === "rejected"
                                      ? "badge-light-danger"
                                      : ""
                                  }${
                                    item.order_status === "ready" ||
                                    item.order_status === "on_the_way"
                                      ? "badge-light-info"
                                      : ""
                                  }`}
                                >
                                  {item.order_status.charAt(0).toUpperCase() +
                                    item.order_status.slice(1)}
                                </div>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {/* {orderStatusList.map((sItem: string, si) => {
                                  return (
                                    <li key={si}>
                                      <a
                                        className={`dropdown-item`}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          updateStatus(item, sItem, i);
                                        }}
                                      >
                                        {sItem.charAt(0).toUpperCase() +
                                          sItem.slice(1)}
                                      </a>
                                    </li>
                                  );
                                })} */}
                                  {props.viewFrom &&
                                    item.order_status != "Delivered" &&
                                    item.order_status != "Order Cancelled" && (
                                      <li>
                                        <a
                                          className={`dropdown-item`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            updateStatus(
                                              item,
                                              item.meta.next_status,
                                              i
                                            );
                                          }}
                                        >
                                          {item.meta.next_status}
                                        </a>
                                        <a
                                          className={`dropdown-item`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            updateStatus(
                                              item,
                                              item.meta.default_status,
                                              i
                                            );
                                          }}
                                        >
                                          {item.meta.default_status}
                                        </a>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </td>
                            <td className="text-center">
                              <a
                                className="btn btn-light-primary btn-sm c-p me-3"
                                data-bs-toggle="modal"
                                data-bs-target="#viewOrders"
                                onClick={(e) => {
                                  setOrderModalData(true);
                                  setOrderDetails(item);
                                }}
                              >
                                View Orders
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    {orderDataList.length == 0 && (
                      <tr>
                        <td colSpan={12} className="text-center">
                          <p>
                            {floorId
                              ? "No orders available on selected floor"
                              : "Please select floor to view orders"}
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {totalPages > 0 && (
                <div className="row mt-2">
                  <div className="col-1">
                    <select
                      name="records_per_age"
                      className="form-select form-select-lg form-select-solid w-auto"
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        setItemsPerPage(value);
                        setTotalPages(Math.ceil(orderDataList.length / value));
                      }}
                      value={itemsPerPage}
                    >
                      {new Array(5).fill(null).map((i, index) => {
                        const value = 5 * (index + 1);
                        return (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-11">
                    {
                      <ResponsivePagination
                        total={totalPages}
                        current={currentPage}
                        onPageChange={(page) => handlePageChange(page)}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="feedbackModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          {viewModal && (
            <FeedbackForm
              setModalData={setModalData}
              setSelectedItem={setSelectedItem}
            />
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="viewOrders"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          {viewOrderModal && (
            <div className="modal-content">
              <div className="modal-header border-0">
                <h2 className="modal-title" id="staticBackdropLabel">
                  Order Details
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  onClick={(e) => {
                    setOrderModalData(false);
                    setOrderDetails({});
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-sm-2 fw-bold col-form-label">
                    Order ID
                  </div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      readOnly
                      value={orderDetails.order_id}
                    />
                  </div>
                  <div className="col-sm-2 fw-bold col-form-label">Status</div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className={`form-control form-control-solid mb-3 mb-lg-0 ${
                        orderDetails.order_status === "approved"
                          ? "text-success"
                          : orderDetails.order_status === "rejected"
                          ? "text-danger"
                          : orderDetails.order_status === "pending"
                          ? "text-warning"
                          : orderDetails.order_status === "preparing"
                          ? "text-info"
                          : orderDetails.order_status === "ready"
                          ? "text-primary"
                          : orderDetails.order_status === "on_the_way"
                          ? "text-secondary"
                          : orderDetails.order_status === "delivered"
                          ? "text-success"
                          : "text-default"
                      }`}
                      readOnly
                      value={orderDetails.order_status}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-2 fw-bold col-form-label">Floor</div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      readOnly
                      value={orderDetails.floor.name}
                    />
                  </div>
                  <div className="col-sm-2 fw-bold col-form-label">Zone</div>
                  <div className="col-sm-4">
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      readOnly
                      value={orderDetails.zone?.name}
                    />
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <div className="col-sm-2 fw-bold col-form-label">Notes</div>
                  <div className="col-sm-10">
                    <input
                      type="text"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                      readOnly
                      value={orderDetails.notes}
                    />
                  </div>
                </div> */}

                <div className="row mb-3">
                  <div className="col-12">
                    <table className="table table-bordered table-striped table-hover caption-top">
                      <caption>Order Items</caption>
                      <thead>
                        <tr>
                          <th scope="col">S.no</th>
                          <th scope="col">Name</th>
                          <th scope="col">Quantitiy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails?.order_items?.map((row, ikey) => {
                          return (
                            <tr key={ikey}>
                              <td>{ikey + 1}</td>
                              <td>{row.name}</td>
                              <td>{row.qty}</td>
                            </tr>
                          );
                        })}
                        {(orderDetails.order_items === null ||
                          !orderDetails?.order_items ||
                          orderDetails?.order_items?.length == 0) && (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No records
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
