import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../sdb/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { ReportsWrapper } from "../pages/Audit Reports/audit_report";
import { getCSSVariableValue } from "../../sdb/assets/ts/_utils";
import { WithChildren } from "../../sdb/helpers";
import { ModuelsPage } from "../modules/apps/modules-page/modulesPage";

import { IntegrationPage } from "../modules/apps/modules-page/integrations";

import { MicrosoftSAML } from "../modules/apps/modules-page/Microsoftsaml";

import { MicrosoftGRAPHAPI } from "../modules/apps/modules-page/microsoftgraphapi";
// import  ViewMicrosoftGRAPHAPI  from "../modules/apps/modules-page/view-microsoftgraphapi";

import { MicrosoftsamlADD } from "../modules/apps/modules-page/add-microsftsaml";

import { MicrosoftGRAPHAPIADD } from "../modules/apps/modules-page/add-microsoftgraphapi";

import { VisitorManagementPage } from "../modules/apps/visitor-management";
import { PantryManagementPage } from "../modules/apps/pantry-management";
import { ItemAdd } from "../modules/apps/pantry-management/ItemAdd";
import { CategoryAdd } from "../modules/apps/pantry-management/CategoryAdd";

import { Tenants } from "../pages/Tenants/Tenant";
import { Userrole } from "../pages/permissions/userrole";
import { ViewTenants } from "../pages/Tenants/view-tenant";

import { ViewPermissions } from "../pages/permissions/view-permissions";
import ViewModules from "../modules/apps/modules-page/view-modules";

import UserAPIpermissions from "../pages/Tenants/userAPIpermissions";

import { AddApi } from "../pages/Tenants/add-api";
import { ViewApi } from "../pages/Tenants/view-api";

import { Addusergroup } from "../pages/Tenants/addusergroup";
import { ViewUsergroup } from "../pages/Tenants/view-usergroup";

import { Servicesgroup } from "../pages/Services group/service-group";
import { Addservicegroup } from "../pages/Services group/add-servicegroup";
import { Viewservicegroup } from "../pages/Services group/view-servicegroup";

import { DeviceManagementPage } from "../modules/apps/device-management";
import { ControllerAdd } from "../modules/apps/device-management/ControllerAdd";
import { Licenses } from "../modules/settings/licenses";

import DeviceAction from '../../app/modules/apps/device-management/Deviceaction'
// Tabs start from VMS
import { PendingVMS } from "../modules/apps/pendingrequest";
// Tabs End from VMS

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );
  const SettingsPage = lazy(() => import("../modules/settings/settings"));
  const PodPage = lazy(() => import("../modules/pod/pod-page"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        {/* <Route path="customers/ " element={<Customers />} /> */}
        <Route path="tenants" element={<Tenants />} />
        <Route path="user_role/list" element={<Userrole />} />
        <Route path="user_role" element={<Userrole />} />
        <Route path="tenants/add" element={<ViewTenants />} />
        <Route path="tenants/:tenantId/manage" element={<ViewTenants />} />

        {/* <Route path="customers/list/:customerId/access" element={<Access />} /> */}
        <Route path="tenants/:tenant/access" element={<UserAPIpermissions />} />

        <Route path="tenants/usergroup/add" element={<Addusergroup />} />
        <Route path="customers/:customerId/edit" element={<Addusergroup />} />
        <Route path="customers/:customerId/view" element={<ViewUsergroup />} />

        <Route path="/servicegroup" element={<Servicesgroup />} />
        <Route path="/serviceaddgroup" element={<Addservicegroup />} />
        <Route path="/service" element={<Addservicegroup />} />

        <Route path="servicesgroup/:/view" element={<Viewservicegroup />} />

        <Route path="tenants/api/add" element={<AddApi />} />
        <Route path="customers/api/:customerId/edit" element={<AddApi />} />
        <Route path="customers/api/:customerId/view" element={<ViewApi />} />
        <Route path="tenants/:customerId/licenses" element={<Licenses />} />
        <Route
          path="permissions/:permissionId/view"
          element={<ViewPermissions />}
        />
        <Route path="modules" element={<ModuelsPage />} />

        <Route path="integrations" element={<IntegrationPage />} />
        <Route path="microsoftsaml" element={<MicrosoftSAML />} />
        <Route path="addmicrosoftsaml" element={<MicrosoftsamlADD />} />
        <Route path="microsoftgraphapi" element={<MicrosoftGRAPHAPI />} />
        <Route path="addgraphapi" element={<MicrosoftGRAPHAPIADD />} />

        <Route
          path="saml/item.customer_id/manage"
          element={<MicrosoftsamlADD />}
        />

        <Route path="modules/:modulesId/view" element={<ViewModules />} />
        <Route
          path="modules/visitor-management"
          element={<VisitorManagementPage />}
        />

        <Route
          path="/deviceaction"
          element={<DeviceAction />}
        />

        <Route
          path="modules/pantry-management"
          element={<PantryManagementPage />}
        />
        <Route
          path="modules/pantry-management/category-add"
          element={<CategoryAdd />}
        />

        <Route path="/Pendingvms" element={<PendingVMS />} />

        <Route
          path="modules/pantry-management/item-add"
          element={<ItemAdd />}
        />
        <Route
          path="modules/device-management"
          element={<DeviceManagementPage />}
        />
        <Route
          path="modules/device-management/controller-add"
          element={<ControllerAdd />}
        />
        <Route
          path="profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="pod/*"
          element={
            <SuspensedView>
              <PodPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route path="auditreports" element={<ReportsWrapper />} />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
