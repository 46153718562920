  import React, { useEffect, useState } from "react";
import {
  getCategoriesList,
  getCategoriesListByCustomer,
  deleteCategory,
} from "./requests";
import { Tooltip } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../utils";
const API_URL = process.env.REACT_APP_API_URL;

export function CategoryTable() {
  const history = useNavigate();
  const { currentUser } = useAuth();
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = categoryList.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterCategoryList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            x.name.toLowerCase().includes(iText) ||
            x.description.toLowerCase().includes(iText)
        );
      }
      setCategoryList(newCategoryList);
      setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const getCategory = async () => {
    try {
      const { data } =
        currentUser.role === "admin"
          ? await getCategoriesListByCustomer(currentUser.customer_id)
          : await getCategoriesList();
      if (data) {
        setCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setFilterCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const deleteRecord = async (item: any, rowIndex: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(item._id)
          .then((r) => {
            let newRecords = [...categoryList];
            let uRecords = newRecords.filter(
              (ditem: any) => ditem._id != item._id
            );
            setCategoryList(uRecords);
            setFilterCategoryList(uRecords);
          })
          .catch((error: any) => {
            Swal.fire("Deleted!", error.response.data.detail, "error");
          });
      }
    });
  };
  useEffect(() => {
    getCategory();
  }, []);
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = () => {
    const sortableItems = [...dataSet];
    if (sortConfig.key !== null && typeof sortConfig.key === "string") {
      const key = sortConfig.key as keyof typeof dataSet[0];
      sortableItems.sort((a, b) => {
        if (a[key] < b[key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  };

  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.slice(0, 2).join(" ") + (words.length > 2 ? "..." : "");
  };
  const renderTooltip = (description) => (
    <Tooltip id="description-tooltip">{description}</Tooltip>
  );
  return (
    <div className="card card-flush my-5">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              value={searchText}
              data-kt-ecommerce-product-filter="search"
              className="form-control form-control-solid w-250px ps-12"
              placeholder="Search Category"
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
              }}
            />
          </div>
        </div>

        <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
          <a
            onClick={(e) => {
              e.preventDefault();
              history("/modules/pantry-management/category-add");
            }}
            className="btn btn-primary"
          >
            Add Category
          </a>
        </div>
      </div>

      <div className="card-body pt-0">
        <table
          className="table align-middle table-row-dashed fs-6 gy-5"
          id="kt_ecommerce_products_table"
        >
          <thead>
            <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
              <th className="w-10px pe-2">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    data-kt-check="true"
                    data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                    value="1"
                  />
                </div>
              </th>
              <th className="min-w-250px" onClick={() => handleSort("name")}>
                Category
                {sortConfig.key === "name" && (
                  <i className={`fas fa-sort-${sortConfig.direction}`} />
                )}
              </th>
              <th
                className="min-w-150px"
                onClick={() => handleSort("is_active")}
              >
                Status
                {sortConfig.key === "is_active" && (
                  <i className={`fas fa-sort-${sortConfig.direction}`} />
                )}
              </th>{" "}
              <th className="text-end min-w-70px">Actions</th>
            </tr>
          </thead>
          <tbody className="fw-semibold text-gray-600">
            {sortedData().map((item: any, i) => {
              return (
                <tr key={i}>
                  <td>
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="1"
                      />
                    </div>
                  </td>
                  <td>
                    <div className="d-flex">
                      <a className="symbol symbol-50px">
                        <span
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${API_URL}/${  encodeURIComponent(item.profile_pic)})`,
                          }}
                        ></span>
                      </a>
                      <div className="ms-5">
                        <a className="text-gray-800 text-hover-primary fs-5 fw-bold mb-1">
                          {item.name}
                        </a>

                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip(item.description)}
                        >
                          <div
                            className="text-muted fs-7 fw-bold"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title={item.description}
                          >
                            {truncateDescription(item.description)}
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </td>
                  <td className="pe-0">
                    <div
                      className={
                        item.is_active
                          ? "badge badge-light-success fw-bolder"
                          : "badge badge-light-danger fw-bolder"
                      }
                    >
                      {item.is_active ? "Active" : "Inactive"}
                    </div>
                  </td>
                  <td className="text-end">
                    <a
                      className="btn btn-light btn-active-light-primary btn-sm"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                      id="dropdownMenuButton1"
                    >
                      Actions
                      <i className="ki-duotone ki-down fs-5 ms-1"></i>
                    </a>

                    <div
                      className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                      data-kt-menu="flase"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="menu-item px-3">
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            history("/modules/pantry-management/category-add", {
                              state: item,
                            });
                          }}
                          className="menu-link px-3"
                        >
                          Edit
                        </a>
                      </div>

                      <div className="menu-item px-3">
                        <a
                          onClick={() => deleteRecord(item, i)}
                          className="menu-link px-3"
                          data-kt-ecommerce-product-filter="delete_row"
                        >
                          Delete
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
            {categoryList.length === 0 && (
              <tr>
                <td colSpan={4} className="text-center">
                  No Records
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="row mt-2">
          <div className="col-1">
            <select
              name="records_per_age"
              className="form-select form-select-lg form-select-solid w-auto"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setItemsPerPage(value);
                setTotalPages(Math.ceil(categoryList.length / value));
              }}
              value={itemsPerPage}
            >
              {new Array(5).fill(null).map((i, index) => {
                const value = 5 * (index + 1);
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-11">
            {
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
