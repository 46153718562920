import { useCallback, useRef } from "react";

export const useDebouncedCallback = (func, wait) => {
    const timeout: any = useRef();

    return useCallback(
        (...args) => {
            const later = () => {
                clearTimeout(timeout.current);
                func(...args);
            };

            clearTimeout(timeout.current);
            timeout.current = setTimeout(later, wait);
        },
        [func, wait]
    );
};