import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const addSetupDataURL = `${API_URL}/vms/v1/setup/`;
const updateSetupDataURL = `${API_URL}/vms/v1/setup/`;
const getSetupDataByCustomerURL = `${API_URL}/vms/v1/setup/customer/`;
const getHostListUrl = `${API_URL}/users/customer/`;
const getVisitorDataByIdURL = `${API_URL}/vms/v1/visit/`;
const visitorSiginURL = `${API_URL}/vms/v1/sign_in/`;
const visitorSigOutURL = `${API_URL}/vms/v1/sign_out/`;
const addVisitorURL = `${API_URL}/vms/v1/host/create/`;
const createvisitorURl=`${API_URL}/vms/v1/`;
const updateenquiry=`${API_URL}/vms/v1/update_expired_status/`;


// location methods
export function addSetupDataByCustomer(data: any) {
  return axios.post<any>(`${addSetupDataURL}`, data);
}
export function getSetupDataByCustomer(id: any) {
  return axios.get<any>(`${getSetupDataByCustomerURL}${id}/`);
}
export function getVisitorDataById(id: any) {
  const url = `${getVisitorDataByIdURL}${id}/`;
  return axios.get<any>(url);
}
export function getVisitorDataByAccessToken(id: any) {
  const url = `${API_URL}/vms/v1/access_token/${id}/`;
  return axios.get<any>(url);
}
export function visitorSigin(code: any) {
  const url = `${visitorSiginURL}${code}/`;
  return axios.put<any>(url);
}
export function visitorSigOut(code: any) {
  const url = `${visitorSigOutURL}${code}/`;
  return axios.put<any>(url);
}


export function getexpiredstatus(id: any) {
  return axios.get<any>(updateenquiry);
}




export function getHostServiceData(customer_id: any, location_id: any) {
  return axios.get<any>(
    `${getHostListUrl}${customer_id}/location/${location_id}/`
  );
}



export function updateSetupDataByCustomer(data: any, id: string) {
  return axios.put<any>(`${updateSetupDataURL}${id}/`, data);
}
export function getVisitorData(customerId, skip, limit) {
  return axios.get<any>(
    `${API_URL}/vms/v1/customer/${customerId}/`
  );
}
export function getVisitorDataByLocation(location_id: string, skip: number, limit: number) {
  return axios.get<any>(
    `${API_URL}/vms/v1/location/${location_id}/`
  );
}



export function getVisitorDataByHost(skip, limit) {
  return axios.get<any>(`${API_URL}/vms/v1/host/`);
}
export function approveReject(url, data) {
  return axios.put<any>(`${API_URL}/${url}`, data);
}
export function addSVisitor(data: any) {
  return axios.post<any>(`${addVisitorURL}`, data);
}
export function getUserByEmail(email: string) {
  const url = `${API_URL}/vms/v1/email/${email}/`;
  return axios.get<any>(url);
}


export function createvisitorURL(data: any) {
  return axios.post<any>(`${createvisitorURl}`, data);
}

