import React, { useEffect, useState } from "react";
import {
  getCategoriesList,
  getCategoriesListByCustomer,
  getZones,
  deleteCategory,
} from "./requests";
import '../pantrymanagement.css'
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import Swal from "sweetalert2";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../utils";
import queryString from "query-string";
import { getLocationByCustomer } from "../../pod/components/requests";
const API_URL = process.env.REACT_APP_API_URL;

interface Category {
  id: number;
  name: string;
  url: string; // Add the 'url' property
  // Other properties if any
}

interface Zone {
  _id: string;
  name: string;
  building: {
    name: string;
    location_id: string;
  };
  floor: {
    name: string;
  };
  wing: {
    name: string;
  };
}

interface Location {
  _id: string;
  name: string;
}
export function PublicURL() {
  const history = useNavigate();
  const { currentUser } = useAuth();
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [dataSet, setDataSet] = useState<Category[]>([]);
  const [searchText, setSearchText] = useState("");
  const [sortOrder, setSortOrder] = useState({
    name: "asc",
  });
  const [urlSortOrder, setUrlSortOrder] = useState("asc");
  const [zones, setZones] = useState<Zone[]>([]);
  const [dataSetZones, setDataSetZones] = useState<Zone[]>([]);

  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setDataSet(categoryList.slice(startIndex, endIndex));
    setDataSetZones(zones.slice(startIndex, endIndex));
  }, [currentPage, itemsPerPage, categoryList, zones]);

  const handleSort = (column) => {
    let sortedList = [...categoryList];
    if (column === "name") {
      const newSortOrder = sortOrder.name === "asc" ? "desc" : "asc";
      console.log(`Sorting by name in ${newSortOrder} order`);
      setSortOrder({ name: newSortOrder });
      sortedList = sortedList.sort((a, b) => {
        if (newSortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    } else if (column === "url") {
      const newUrlSortOrder = urlSortOrder === "asc" ? "desc" : "asc";
      console.log(`Sorting by URL in ${newUrlSortOrder} order`);
      setUrlSortOrder(newUrlSortOrder);
      sortedList = sortedList.sort((a, b) => {
        if (a.url && b.url) {
          if (newUrlSortOrder === "asc") {
            return a.url.localeCompare(b.url);
          } else {
            return b.url.localeCompare(a.url);
          }
        } else {
          return 0;
        }
      });
    }
    console.log("Sorted list:", sortedList);
    setCategoryList(sortedList);
  };

  const renderSortIcon = (column) => {
    if (column === "name") {
      return sortOrder.name === "asc" ? (
        <span className="sort-icon">▲</span>
      ) : (
        <span className="sort-icon">▼</span>
      );
    } else if (column === "url") {
      return urlSortOrder === "asc" ? (
        <span className="sort-icon">▲</span>
      ) : (
        <span className="sort-icon">▼</span>
      );
    }
    return null;
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const { data } =
        currentUser.role === "admin"
          ? await getZones()
          : await getCategoriesList();
      if (data) {
        setCategoryList(data);
        // console.log(data);

        setFilterCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getLocations = async (customerId: number) => {
    try {
      const { data } = await getLocationByCustomer(customerId);
      if (data) {
        setLocations(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      getLocations(currentUser.customer_id);
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const { data } = await getZones();
        if (data) {
          setZones(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchZones();
  }, []);

  const generateEncodedString = (zone) => {
    if (zone && zone.building) {
      const { _id, customer_id, building, floor_id } = zone;
      const { location_id } = building;
      const query_string = `pantry&tid=${currentUser.customer_id}&lid=${location_id}&fid=${floor_id}&zid=${_id}`;
      return btoa(query_string);
    }
    return "";
  };

  const [baseURL, setBaseURL] = useState("");

  useEffect(() => {
    const base_url = `${window.location.host}/pms/home?q=`;
    setBaseURL(base_url);
  }, []);

  const [copiedUrl, setCopiedUrl] = useState("");
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedUrl(text);
    setCopiedIndex(index);

    setTimeout(() => {
      setCopiedUrl("");
      setCopiedIndex(null);
    }, 2000);
  };

  const getLocationName = (location_id) => {
    const location = locations.find((loc) => loc._id === location_id);
    return location ? location.name : "";
  };

  return (
    <div className="card card-flush my-5 ">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              value={searchText}
              data-kt-ecommerce-product-filter="search"
              className="form-control form-control-solid w-250px ps-12"
              placeholder="Search Category"
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="card-body pt-0">
        <div style={{ overflowX: "auto" }}>
          <table
            className="table align-middle table-row-dashed fs-6 gy-5"
            id="kt_ecommerce_products_table"
           
          >
            <thead>
              <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                <th className="w-10px pe-2">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                      value="1"
                    />
                  </div>
                </th>
                <th className="min-w-250px" onClick={() => handleSort("name")}>
                  Name {renderSortIcon("name")}
                </th>
                <th className="min-w-150px" onClick={() => handleSort("url")}>
                  URL
                </th>
              </tr>
            </thead>
            <tbody className="fw-semibold text-gray-600">
              {dataSet.map((item, i) => {
                const zone = dataSetZones[i];
                const encodedString = generateEncodedString(zone);
                const locationName = zone
                  ? getLocationName(zone.building.location_id)
                  : "";
                const zoneDetails = zone
                  ? `${zone.name} | ${locationName} | ${zone.building?.name}`
                  : // | ${zone.floor?.name || ''} | ${zone.wing?.name || ''}

                    // `
                    "";
                const [zoneName, ...zoneRest] = zoneDetails.split(" | ");

                return (
                  <tr key={i}>
                    <td>
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={item.id}
                        />
                      </div>
                    </td>
                    {/* <td>{zoneDetails}</td> */}
                    <td>
                      <span style={{ fontWeight: "bold" }}>{zoneName}</span>
                      <span> | {zoneRest.join(" | ")}</span>
                    </td>
                    <td className="pe-0">
                      <div title={`${baseURL}${encodedString}`}>
                        {`${baseURL}${encodedString.substring(0, 50)}...`}
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm btn-light btn-icon btn-icon-primary ms-2"
                        onClick={() =>
                          handleCopy(`${baseURL}${encodedString}`, i)
                        }
                        title="Copy to clipboard"
                      >
                        <i className="bi bi-clipboard"></i>
                      </button>
                      {copiedIndex === i && (
                        <span style={{ marginLeft: "8px" }}>
                          Your URL is copied
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row mt-2">
          <div className="col-1">
            <select
              name="records_per_age"
              className="form-select form-select-lg form-select-solid w-auto"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setItemsPerPage(value);
                setTotalPages(Math.ceil(categoryList.length / value));
              }}
              value={itemsPerPage}
            >
              {new Array(5).fill(null).map((i, index) => {
                const value = 10 * (index + 1);
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-11">
            {
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}
