import { Route, Routes } from "react-router-dom";
import { Registration } from "./components/Registration";
import { ForgotPassword } from "./components/ForgotPassword";
import { ChangePassword } from "./components/ChangePassword";
import { VerifyOTP } from "./components/VerifyOTP";
import { Login } from "./components/Login";
import { AuthLayout } from "./AuthLayout";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="registration" element={<Registration />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="change-password" element={<ChangePassword />} />
      <Route path="verify-top" element={<VerifyOTP />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
