import { Field, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import { useListView } from "./user-management/users-list/core/ListViewProvider";
import {
  getsingleUsergroup,
  enableModulesAccess,
} from "../../pages/Tenants/requests";
import {
  getZones,
  getCategoriesList,
  getlocationlist,
} from "../apps/pantry-management/requests";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

import {
  addSetupDataByCustomer,
  approveReject,
  getSetupDataByCustomer,
  getVisitorData,
  getVisitorDataByHost,
  getVisitorDataByLocation,
  updateSetupDataByCustomer,
} from "./request";
import { useAuth } from "../auth";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { VisitorInfo } from "./view-visitor-info";
import AddVisitorInfo from "./add-visitor-info";
import { useDebouncedCallback } from "../../utils";
import { Console } from "console";
interface VisitData {
  status: string;
  // Add other relevant fields based on your data structure
}
interface VisitorData {
  visitor_profile: {
    full_name: string;
    email: string;
  };
  host_name: string;
  visit_data: {
    visiting_date_time: string;
    purpose_of_visit: string;
    access_token: string;
    sign_in_time: string;
    sign_out_time: string;
    status: string;
    meta: {
      status_color_code: string;
    };
  };
}
const editUserSchema = Yup.object().shape({
  // duration: Yup.string().required("Duration is required"),
  identification_type: Yup.string().required("Identification type is required"),
  visitor_type: Yup.string().required("Visitor type is required"),
  purpose_of_visit: Yup.string().required("purpose ofvisit is required"),
});

export function History() {
  const { currentUser, logout } = useAuth();
  const { isAllSelected, onSelectAll } = useListView();
  const { selected, onSelect } = useListView();
  const isSelected = false;
  const [setupData, setSetupData] = useState(null);
  const [activeView, setActiveView] = useState(
    currentUser.role === "admin" ? "setup" : "pending"
  );
  const [visitorDataList, setVisitorDataList] = useState<VisitorData[]>([]);
  const [filteredVisitorDataList, setFilterVisitorDataList] = useState<
    VisitorData[]
  >([]);

  const [currentPage, setCurrentPage] = useState(0);
  // const [currentPage_1, setCurrentPage_1] = useState(10);

  const [totalPages, setTotalPages4] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = visitorDataList.slice(startIndex, endIndex);
  const [locationlist, setLocationlist] = useState<VisitorData[]>([]);

  const locSet = locationlist.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filteredVisitorDataList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            (x.host_name && x.host_name.toLowerCase().includes(iText)) ||
            (x.visitor_profile &&
              x.visitor_profile.email &&
              x.visitor_profile.email.toLowerCase().includes(iText)) ||
            (x.visitor_profile &&
              x.visitor_profile.full_name &&
              x.visitor_profile.full_name.toLowerCase().includes(iText)) ||
            (x.visit_data &&
              x.visit_data.purpose_of_visit &&
              x.visit_data.purpose_of_visit.toLowerCase().includes(iText))
        );
      }
      setVisitorDataList(newCategoryList);
      setLocationlist(newCategoryList);

      setTotalPages4(
        Math.ceil(
          (newCategoryList.length || locationlist.length) / itemsPerPage
        )
      );
      // setTotalPages1(Math.ceil(locationlist.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const [viewModal, setModalData] = useState(false);
  // const [showAddVisitModal, setShowAddVisitModal] = useState(false);
  const [userGroupList, setUserGroupList] = useState([]);
  const [value, setValue] = useState<any>([]);
  const [userGroupAccess, setuserGroupAccess] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const divRef = useRef<HTMLDivElement | null>(null);
  // const addClass = () => {
  //   setShowAddVisitModal(true);
  //   divRef.current?.classList.add("main-div");
  // };

  // const removeClass = () => {
  //   divRef.current?.classList.remove("main-div");
  // };

  const initialValues = {
    customer_id: "",
    location_id: "",
    // duration: "",
    purpose_of_visit: "",
    identification_type: "",
    visitor_type: "",
  };
  const formik: any = useFormik({
    initialValues,
    validationSchema: editUserSchema,
    onSubmit: (values: any) => {
      if (values._id) {
        updateSetupDataByCustomer(values, values._id)
          .then((daa: any) => {
            Swal.fire("Data setup saved!", "", "success");
          })
          .catch((error: any) => {
            Swal.fire(
              "Data setup failed!",
              error.response.data.detail,
              "error"
            );
          });
      } else {
        values["customer_id"] = currentUser.customer_id;
        values["location_id"] = currentUser.location_id
          ? currentUser.location_id[0]
          : null;

        addSetupDataByCustomer(values)
          .then((daa: any) => {
            Swal.fire("Data setup saved!", "", "success");
          })
          .catch((error: any) => {
            Swal.fire(
              "Data setup failed!",
              error.response.data.detail,
              "error"
            );
          });
      }
    },
  });
  const getSetupData = async (customer_id: string) => {
    try {
      const { data } = await getSetupDataByCustomer(customer_id);
      if (data) {
        formik.setValues(data);
        setSetupData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVisitorDataForAllLocations = async (locationIds: string[]) => {
    try {
      const allData = await Promise.all(
        locationIds.map((locationId) =>
          getVisitorDataByLocation(locationId, 0, 20).then(
            (response) => response.data
          )
        )
      );

      const combinedData: VisitorData[] = allData.flat();

      if (combinedData) {
        // console.log(combinedData);

        const filteredData = combinedData.filter(
          (item) =>
            item.visit_data.status === "Expired" ||
            item.visit_data.status === "Checked Out" ||
            item.visit_data.status === "Rejected"
        );

        setVisitorDataList(filteredData);
        setFilterVisitorDataList(filteredData);

        // setTotalPages1(Math.ceil(filteredData.length / itemsPerPage));

        combinedData.forEach((item) => {
          if (item.visit_data.status === "Checked Out") {
            // Handle "Checked Out" status if needed
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVisitorDatas = async (customerId: string) => {
    try {
      const { data } = await getVisitorData(customerId, 0, 20);
      if (data) {
        // Filter the data based on the status
        const filteredData = data.filter(
          (item) =>
            item.visit_data.status === "Expired" ||
            item.visit_data.status === "Checked Out" ||
            item.visit_data.status === "Rejected"
        );

        // Sort the filtered data based on the created_at date in descending order
        const sortedData = filteredData.sort((a, b) => {
          return (
            new Date(b.visit_data.created_at).getTime() -
            new Date(a.visit_data.created_at).getTime()
          );
        });

        // Set the sorted data as visitor data list
        setVisitorDataList(sortedData);
        setFilterVisitorDataList(sortedData);
        // console.log("admin:", sortedData);

        // Calculate the total pages based on the sorted data
        setTotalPages4(Math.ceil(sortedData.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVisitorInfoByHost = async () => {
    try {
      const { data } = await getVisitorDataByHost(0, 20);
      if (data) {
        const filteredData = data.filter(
          (item) =>
            item.visit_data.status === "Expired" ||
            item.visit_data.status === "Checked Out" ||
            item.visit_data.status === "Rejected"
        );

        // Set the filtered data as visitor data list
        setVisitorDataList(filteredData);
        setFilterVisitorDataList(filteredData);
        // console.log("Hostuser:", filteredData);

        // Calculate the total pages based on the filtered data
        setTotalPages4(Math.ceil(filteredData.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveUserGroupAccess = async () => {
    try {
      let data = {
        customer_id: currentUser.customer_id,
        user_group: userGroupAccess,
      };
      enableModulesAccess(data, id);
    } catch (error) {
      console.error(error);
    }
  };
  const getUsergroup = async (customerId: number) => {
    try {
      const { data } = await getsingleUsergroup(customerId);
      if (data) {
        const groupData = data.map((item: any) => {
          return {
            ...item,
            id: item._id,
          };
        });
        groupData.forEach((element: any) => {
          element["value"] = element.id;
          element["label"] = element.name;
        });
        setUserGroupList(groupData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const approveRejectUser = async (item: any, flag: boolean) => {
    const updateURL =
      currentUser.role === "facility"
        ? `vms/v1/facility/visit/${item.visit_data._id}/`
        : `vms/v1/host/visit/${item.visit_data._id}/`;
    const postData =
      currentUser.role === "facility"
        ? {
            meta: {
              admin_approval: flag,
            },
          }
        : {
            meta: {
              host_approval: flag,
            },
          };
    try {
      const { data } = await approveReject(updateURL, postData);
      if (data) {
        getVisitorInfo();
        Swal.fire(
          flag ? "Approved" : "Rejected",
          `User has been ${flag ? "approved" : "rejected"} succefully!`,
          "success"
        );
      }
    } catch (error) {
      console.error(error);
      Swal.fire(
        flag ? "Approved" : "Rejected",
        `Failed to ${flag ? "approved" : "rejected"} user!`,
        "error"
      );
    }
  };
  const getVisitorInfo = () => {
    if (currentUser.role === "facility") {
      const locationIds = currentUser.location_id
        ? currentUser.location_id
        : [];
      getVisitorDataForAllLocations(locationIds);
    } else if (currentUser.role === "user") {
      getVisitorInfoByHost();
    } else {
      getVisitorDatas(currentUser.customer_id);
    }
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      getVisitorInfo();
    }, 30000);
    setModalData(false);
    // setShowAddVisitModal(false);
    /*  setSelectedItem(null); */
    getSetupData(currentUser.customer_id);
    getVisitorInfo();
    getUsergroup(currentUser.customer_id);
    return () => clearInterval(intervalId);
  }, []);

  // part -2

  const history = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  useEffect(() => {
    const fetchlocations = async () => {
      try {
        const { data } = await getlocationlist();
        if (data) {
          setLocationlist(data);
          // console.log(data);
          // setTotalPages(Math.ceil(data.length / itemsPerPage));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchlocations();
  }, []);

  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column) => {
    // console.log("Sorting column:", column);
    const newDirection =
      sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    // console.log("New sort direction:", newDirection);
    setSortColumn(column);
    setSortDirection(newDirection);
    sortData(column, newDirection);
  };

  const sortData = (column, direction) => {
    const sortedData = [...visitorDataList].sort((a, b) => {
      // Extracting values to compare
      const valueA = getColumnValue(a, column);
      const valueB = getColumnValue(b, column);

      if (typeof valueA === "string" && typeof valueB === "string") {
        // Case-insensitive string comparison
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        // Numeric comparison
        return direction === "asc" ? valueA - valueB : valueB - valueA;
      }
      // Add more comparisons for other data types if needed
      return 0;
    });

    // Set the sorted data to the state
    setVisitorDataList(sortedData);
  };

  const getColumnValue = (dataItem, column) => {
    // Handle nested properties like "visitor_profile.full_name"
    const properties = column.split(".");
    let value = dataItem;
    for (let property of properties) {
      value = value[property];
      if (value === undefined || value === null) return "";
    }
    return value;
  };

  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              data-kt-user-table-filter="search"
              className="form-control form-control-solid w-250px ps-14"
              placeholder="Search"
              value={searchText}
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
              }}
            />
          </div>
        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >
            {/* {currentUser.role === "admin" && (
              <button
                type="button"
                className="btn btn-light-primary me-3"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <i className="ki-duotone ki-filter fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                Filter
              </button>
            )} */}
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
            <thead>
              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                <th>
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check={isAllSelected}
                      data-kt-check-target="#kt_table_users .form-check-input"
                      checked={isAllSelected}
                      onChange={onSelectAll}
                    />
                  </div>
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visitor_profile.full_name")}
                >
                  Visitor Name{" "}
                  {sortColumn === "visitor_profile.full_name" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visitor_profile.email")}
                >
                  Visitor Email ID{" "}
                  {sortColumn === "visitor_profile.email" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("host_name")}
                >
                  Employee Name{" "}
                  {sortColumn === "host_name" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.visiting_date_time")}
                >
                  Visiting Date{" "}
                  {sortColumn === "visit_data.visiting_date_time" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.visiting_date_time")}
                >
                  Visiting Time{" "}
                  {sortColumn === "visit_data.visiting_date_time" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.purpose_of_visit")}
                >
                  Purpose of Visit{" "}
                  {sortColumn === "visit_data.purpose_of_visit" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.access_token")}
                >
                  Access Token{" "}
                  {sortColumn === "visit_data.access_token" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.sign_in_time")}
                >
                  Sign-in Time{" "}
                  {sortColumn === "visit_data.sign_in_time" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-150px"
                  onClick={() => handleSort("visit_data.sign_out_time")}
                >
                  Sign-out Time{" "}
                  {sortColumn === "visit_data.sign_out_time" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>
                <th
                  className="min-w-90px"
                  onClick={() => handleSort("visit_data.status")}
                >
                  Status{" "}
                  {sortColumn === "visit_data.status" &&
                    (sortDirection === "asc" ? "▲" : "▼")}
                </th>

                <th>Action</th>
              </tr>
            </thead>

            <tbody className="text-gray-600 fw-bold">
              {dataSet.map((item: any, i) => {
                if (
                  item &&
                  item.visitor_profile &&
                  item.visitor_profile.full_name &&
                  item.visitor_profile.email
                ) {
                  if (
                    item.visit_data.status === "Expired" ||
                    item.visit_data.status === "Checked Out" ||
                    item.visit_data.status === "Rejected"
                  ) {
                    // if (
                    //   item.visit_data.status === "Pending" ||
                    //   item.visit_data.status ===
                    //     "Facility Approval Pending"
                    // )
                    return (
                      <tr role="row" key={i}>
                        <td>
                          <div className="form-check form-check-custom form-check-solid">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              data-kt-check={isSelected}
                              data-kt-check-target="#kt_table_users .form-check-input"
                              checked={isSelected}
                              onChange={() => onSelect(10)}
                            />
                          </div>
                        </td>
                        {/* <td>{item.visit_data.visitor_id}</td> */}
                        <td>
                          {item.visitor_profile.full_name
                            .charAt(0)
                            .toUpperCase() +
                            item.visitor_profile.full_name
                              .slice(1)
                              .toLowerCase()}
                        </td>
                        <td>{item.visitor_profile.email.toLowerCase()}</td>
                        <td>{item.host_name}</td>
                        <td>
                          {item.visit_data.visiting_date_time
                            ? new Date(
                                item.visit_data.visiting_date_time
                              ).toLocaleDateString()
                            : ""}
                        </td>
                        <td>
                          {item.visit_data.visiting_date_time
                            ? new Date(
                                item.visit_data.visiting_date_time
                              ).toLocaleTimeString()
                            : ""}
                        </td>
                        <td>{item.visit_data.purpose_of_visit}</td>
                        <td>{item.visit_data.access_token}</td>
                        <td>
                          {item.visit_data.sign_in_time
                            ? new Date(
                                item.visit_data.sign_in_time
                              ).toLocaleTimeString()
                            : "N/A"}
                        </td>
                        <td>
                          {item.visit_data.sign_out_time
                            ? new Date(
                                item.visit_data.sign_out_time
                              ).toLocaleTimeString()
                            : "N/A"}
                        </td>

                        <td>
                          <div
                            data-bs-toggle={
                              (currentUser.role === "user" &&
                                item.visit_data?.status.toLowerCase() ===
                                  "pending") ||
                              (currentUser.role === "admin" &&
                                item.visit_data?.status.toLowerCase() ===
                                  "pending") ||
                              (currentUser.role === "facility" &&
                                item.visit_data?.status.toLowerCase() ===
                                  "facility approval pending")
                                ? "dropdown"
                                : ""
                            }
                            aria-expanded="false"
                            className={"badge fw-bolder dropdown-toggle"}
                            style={{
                              backgroundColor:
                                item.visit_data.meta.status_color_code,
                            }}
                          >
                            {item.visit_data.status === "pending"
                              ? "Pending"
                              : item.visit_data.status === "approved"
                              ? "Approved"
                              : item.visit_data.status}
                          </div>
                          {currentUser.role === "user" &&
                            item.visit_data?.status.toLowerCase() ===
                              "pending" && (
                              <React.Fragment>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, true)
                                      }
                                    >
                                      Approve
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, false)
                                      }
                                    >
                                      Reject
                                    </a>
                                  </li>
                                </ul>
                              </React.Fragment>
                            )}
                          {currentUser.role === "facility" &&
                            item.visit_data?.status.toLowerCase() ===
                              "facility approval pending" && (
                              <React.Fragment>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, true)
                                      }
                                    >
                                      Approve
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, false)
                                      }
                                    >
                                      Reject
                                    </a>
                                  </li>
                                </ul>
                              </React.Fragment>
                            )}
                          {currentUser.role === "admin" &&
                            item.visit_data?.status.toLowerCase() ===
                              "pending" && (
                              <React.Fragment>
                                <ul className="dropdown-menu">
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, true)
                                      }
                                    >
                                      Approve
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      className="dropdown-item"
                                      onClick={() =>
                                        approveRejectUser(item, false)
                                      }
                                    >
                                      Reject
                                    </a>
                                  </li>
                                </ul>
                              </React.Fragment>
                            )}
                        </td>

                        <td>
                          <a
                            className="btn btn-light btn-active-light-primary btn-sm"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-bs-toggle="dropdown"
                            aria-expanded="true"
                            id="dropdownMenuButton4"
                          >
                            Action
                            <i className="ki-duotone ki-down fs-5 ms-1"></i>
                          </a>

                          <div
                            className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                            data-kt-menu="flase"
                            aria-labelledby="dropdownMenuButton4"
                          >
                            <div className="menu-item px-3">
                              <a
                                className="menu-link px-3"
                                data-bs-toggle="modal"
                                data-bs-target="#visitorInfoModal"
                                onClick={() => {
                                  setModalData(true);
                                  setSelectedItem(item);
                                }}
                              >
                                View
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                }
              })}
            </tbody>
          </table>
        </div>
        <div className="row mt-2">
          <div className="col-1">
            <select
              name="records_per_age"
              className="form-select form-select-lg form-select-solid w-auto"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setItemsPerPage(value);
                setTotalPages4(Math.ceil(visitorDataList.length / value));
              }}
              value={itemsPerPage}
            >
              {new Array(5).fill(null).map((i, index) => {
                const value = 10 * (index + 1);
                return (
                  <option key={index} value={value}>
                    {value}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-11">
            {
              <ResponsivePagination
                total={totalPages}
                current={currentPage}
                onPageChange={(page) => handlePageChange(page)}
              />
            }
          </div>
        </div>
      </div>
      <div
        ref={divRef}
        className="modal fade"
        id="visitorInfoModal"
        data-bs-backdrop="false"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
          style={{ marginTop: "2cm" }}
        >
          <div className="modal-content">
          {viewModal ? (
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {viewModal ? "View Info" : " "}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  id="modalCloseBtn"
                  data-bs-dismiss="modal"
                  style={{
                    position: "absolute",
                    top: "30px",
                    right: "1cm",
                    background: "#D41920",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "10px",
                    height: "10px",
                  }}
                  aria-label="Close"
                  onClick={() => {
                    // window.location.reload()
                    // onClose(); // Call the function to update the state in the parent

                    setModalData(false);
                    // setShowAddVisitModal(false);
                    // removeClass();
                  }}
                >           <i className="fa fa-times"></i>
</button>
              </div>
            ) : (
              " "
            )}
            <div className="modal-body">
              {viewModal ? (
                <VisitorInfo
                  visitorInfo={selectedItem}
                  modalCallBack={() => {
                    setSelectedItem(null);
                    setModalData(false);
                  }}
                />
              ) : (
                <AddVisitorInfo
                  model_ref={divRef}
                  modalCallBack={() => {
                    // setShowAddVisitModal(false);
                    document.getElementById("modalCloseBtn")?.click();
                  }}
                />
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
