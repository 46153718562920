import React, { useState, useEffect } from "react";
import MultiSelect from "@kenshooui/react-multi-select";
import { useLocation } from "react-router-dom";
import { getEndpoints } from "./requests";
import { PageTitle } from "../../../sdb/layout/core";

export function ViewUsergroup() {
  const location: any = useLocation();
  let viewData = location.state;
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [endpointList, setEndpointList] = useState<any>([]);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");

  const getEndpointsData = async () => {
    try {
      const { data } = await getEndpoints();
      if (data) {
        const newData = data.map((item: any) => ({
          id: item.route,
          label: item.tags + " | " + item.name,
          disabled: true,
          ...item,
        }));
        setEndpointList(newData);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    if (viewData) {
      const newViewData = viewData?.permission?.map((item: any) => ({
        id: item.name,
        label: item.name,
        disabled: true,
        ...item,
      }));
      setSelectedItems(newViewData);
      setDescription(viewData.description);
      setName(viewData.name);
    }
    getEndpointsData();
  }, []);

  return (
    <>
      <PageTitle
        breadcrumbs={[
          // {
          //   title: "Customers",
          //   path: "/customers",
          //   isSeparator: false,
          //   isActive: false,
          // },
          // {
          //   title: "",
          //   path: "",
          //   isSeparator: true,
          //   isActive: false,
          // },
          {
            title: "Service Group",
            path: `/servicegroup`,
            isSeparator: false,
            isActive: false,
          },
          {
            title: "",
            path: "",
            isSeparator: true,
            isActive: false,
          },
        ]}
      >
        View Service Group
      </PageTitle>
      <div className="row">
        <div className="d-flex mb-4">
          <div className="col-6 me-4">
            <label className=" fw-bold fs-6 mb-2">Name</label>
            <input
              type="text"
              name="name"
              className="form-control form-control-solid mb-3 mb-lg-0"
              autoComplete="off"
              value={name}
              disabled
            />
          </div>
          <div className="col-6">
            <label className=" fw-bold fs-6 mb-2">Description</label>
            <textarea
              className="form-control form-control-solid mb-3"
              rows={3}
              data-kt-element="input"
              value={description}
              disabled
            ></textarea>
          </div>
        </div>
        <MultiSelect
          items={endpointList}
          selectedItems={selectedItems}
          disabled={endpointList.disabled}
        />
      </div>
    </>
  );
}
