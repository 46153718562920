/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import {
  addLicenseInfo,
  getLicenseInfoByCustomerId,
  updateLicenseInfo,
} from "../../modules/pod/components/requests";
import { useLocation, useNavigate } from "react-router-dom";
import { PageTitle } from "../../../sdb/layout/core";

export function Licenses() {
  const location: any = useLocation();
  const customerId = location.state._id;
  const history = useNavigate();
  const [license, setLicenseInfo] = useState<any>({
    customer_id: customerId,
    location_limit: "",
    building_limit: "",
    floor_limit: "",
    zone_limit: "",
    wing_limit: "",
    device_limit: "",
    controller_limit: "",
    user_limit: "",
    id: "",
  });
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
    }
  };

  const formik = useFormik({
    initialValues: license,
    // validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        const data: Promise<any> = (await license.id)
          ? updateLicenseInfo(values, license._id)
          : addLicenseInfo(values);
        data
          .then((res: any) => {
            Swal.fire("Licenses!", "Licenses info saved");
            history("/tenants");
          })
          .catch((error: any) => {
            Swal.fire("Licenses!", error.response.data.detail, "error");
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(true);
        cancel(true);
      }
    },
  });
  const getLicenseInfo = async () => {
    try {
      const { data } = await getLicenseInfoByCustomerId(customerId);
      if (data) {
        data["id"] = data._id;
        setLicenseInfo(data);
        formik.setValues(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLicenseInfo();
  }, []);
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        {/* SDB-6(Breadcrumb Name changed:Customers to Tenants ) */}

        <PageTitle
          breadcrumbs={[
            {
              title: (
                <span
                  className="breadcrumb-title"
                  style={{
                    display: "inline-block",
                    color: "inherit",
                    textDecoration: "none",
                    backgroundColor: "transparent",
                    borderRadius: "3px",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.textDecoration = "underline";
                    e.currentTarget.style.backgroundColor = "#f0f8ff";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.color = "inherit";
                    e.currentTarget.style.textDecoration = "none";
                    e.currentTarget.style.backgroundColor = "transparent";
                  }}
                >
                  Tenants 
                </span>
              ),
              path: "/tenants",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "Manage Customers",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
          {/* SDB-6(Name changed:Licenses to Tenant Limits ) */}
          Tenant Limits
        </PageTitle>
      </div>
      <div className="row">
        <form className="form" onSubmit={formik.handleSubmit}>
          <div className="col-12">
            <div className="card">
              {/* <div className="card-header border-0 pt-2">
                Licences Information
              </div> */}
              <div className="card-body py-4">
                <div className="row mb-7">
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Site Limit</label>
                    <input
                      placeholder="Site Limit"
                      type="number"
                      {...formik.getFieldProps("location_limit")}
                      name="location_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Building Limit</label>
                    <input
                      placeholder="Building Limit"
                      type="number"
                      {...formik.getFieldProps("building_limit")}
                      name="building_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Floor Limit</label>
                    <input
                      placeholder="Floor Limit"
                      type="number"
                      {...formik.getFieldProps("floor_limit")}
                      name="floor_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Wing Limit</label>
                    <input
                      placeholder="Wing Limit"
                      type="number"
                      {...formik.getFieldProps("wing_limit")}
                      name="wing_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                </div>
                <div className="row mb-7">
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Zone Limit</label>
                    <input
                      placeholder="Zone Limit"
                      type="number"
                      {...formik.getFieldProps("zone_limit")}
                      name="zone_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">Device Limit</label>
                    <input
                      placeholder="Device Limit"
                      type="number"
                      {...formik.getFieldProps("device_limit")}
                      name="device_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                </div>
                <div className="row mb-7">
                  {" "}
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">
                      Controller Limit
                    </label>
                    <input
                      placeholder="Controller Limitvi"
                      type="number"
                      {...formik.getFieldProps("controller_limit")}
                      name="controller_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className=" fw-bold fs-6 mb-2">User Limit</label>
                    <input
                      placeholder="User Limit"
                      type="number"
                      {...formik.getFieldProps("user_limit")}
                      name="user_limit"
                      autoComplete="off"
                      className="form-control form-control-solid mb-3 mb-lg-0"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  // onClick={() => }
                  disabled={
                    formik.isSubmitting || !formik.isValid || !formik.touched
                  }
                >
                  <span className="indicator-label">Save</span>
                  {formik.isSubmitting && (
                    <span className="indicator-progress">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
