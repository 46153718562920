/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

import { ItemTable } from "./pantry-management/ItemTable";
import { CategoryTable } from "./pantry-management/CategoryTable";
import { OrderList } from "../public/pantry/orderList";
import { PantryAccess } from "./pantry-management/PantryAccess";
import { PublicURL } from "./pantry-management/publicURL";
import { useLocation } from "react-router-dom";
import { useAuth } from "../auth";
import { Pendingorders } from "../public/pantry/pendingorders";
import "./pantrymanagement.css";
import { Historypantry } from "../public/pantry/historypantry";
export function PantryManagementPage() {
  const { currentUser } = useAuth();
  const location = useLocation();
  const locationParams: any = location.state;
  const [activeView, setActiveView] = useState(
    locationParams
      ? locationParams.view
      : currentUser.role === "user" || currentUser.role === "facility"
      ? "orders"
      : "category"
  );

  const [containerClass, setContainerClass] = useState("container");

  useEffect(() => {
    const updateClass = () => {};

    updateClass();
    window.addEventListener("resize", updateClass);

    return () => window.removeEventListener("resize", updateClass);
  }, []);
  return (
    <>
      {/* <div className={`card card-flush my-5" ${containerClass}`}> */}

        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-1">
            <div
              id="kt_app_toolbar_container"
              className="app-container-fluid  d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-0">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  Pantry Management
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-mutedforbreadcrumb ">
                    <a 
                      href="/modules"
                      className="text-muted text-hover-primary"
                    >
                      SDB Services{" "}
                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>

                  <li className="breadcrumb-item text-muted">
                    Pantry Management
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container-fluid ">
              <div className="d-flex h-55px">
                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                  {currentUser.role === "admin" && (
                    <React.Fragment>
                      <li className="nav-item">
                        <a
                          onClick={() => setActiveView("category")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "category" && "active")
                          }
                        >
                          Category
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          onClick={() => setActiveView("item")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "item" && "active")
                          }
                        >
                          Item
                        </a>
                      </li>
                      {(currentUser.role === "user" ||
                        currentUser.role === "facility" ||
                        currentUser.role === "admin") && (
                        <li className="nav-item">
                          <a
                            onClick={() => setActiveView("orders")}
                            className={
                              `nav-link text-active-primary me-6 c-p ` +
                              (activeView === "orders" && "active")
                            }
                          >
                            Orders
                          </a>
                        </li>
                      )}

                      <li className="nav-item">
                        <a
                          onClick={() => setActiveView("publicurl")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "publicurl" && "active")
                          }
                        >
                          Public URL
                        </a>
                      </li>
                      {/* <li className="nav-item">
                        <a
                          onClick={() => setActiveView("access")}
                          className={
                            `nav-link text-active-primary me-6 c-p ` +
                            (activeView === "access" && "active")
                          }
                        >
                          Access
                        </a>
                      </li> */}
                    </React.Fragment>
                  )}

                  {(currentUser.role === "user" ||
                    currentUser.role === "facility") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("orders")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "orders" && "active")
                        }
                      >
                        Recent Orders
                      </a>
                    </li>
                  )}
                  {(currentUser.role === "user" ||
                    currentUser.role === "facility") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("pendingorders")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "pendingorders" && "active")
                        }
                      >
                        Pending Orders
                      </a>
                    </li>
                  )}

                  {(currentUser.role === "user" ||
                    currentUser.role === "facility") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("Historypantry")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "Historypantry" && "active")
                        }
                      >
                        History
                      </a>
                    </li>
                  )}

                  {/* {(currentUser.role === "user" ||
                    currentUser.role === "facility") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "" && "")
                        }
                      >
                        Pantry Items availability list
                      </a>
                    </li>
                  )}
                  {(currentUser.role === "user" ||
                    currentUser.role === "facility") && (
                    <li className="nav-item">
                      <a
                        onClick={() => setActiveView("")}
                        className={
                          `nav-link text-active-primary me-6 c-p ` +
                          (activeView === "" && "")
                        }
                      >
                        Feedback and Ratings
                      </a>
                    </li>
                  )} */}
                </ul>
              </div>
              {activeView === "category" && <CategoryTable />}
              {activeView === "item" && <ItemTable />}
              {activeView === "publicurl" && <PublicURL />}
              {activeView === "pendingorders" && (
                <Pendingorders viewFrom={"hostuser"} />
              )}
              {activeView === "Historypantry" && (
                <Historypantry viewFrom={"hostuser"} />
              )}

              {activeView === "access" && <PantryAccess />}
              {activeView === "orders" && <OrderList viewFrom={"hostuser"} />}
            </div>
          </div>
        </div>
      {/* </div> */}
    </>
  );
}
