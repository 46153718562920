/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../app/modules/auth";
import clsx from "clsx";
const API_URL = process.env.REACT_APP_API_URL;

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [imageUrl, setImgUrl] = useState(
    currentUser?.profile_pic ? `${API_URL}/${currentUser?.profile_pic}` : ""
  );
  const onError = () => {
    setImgUrl("");
  };
  const capitalizeFirstLetter = (str) => {
    if (!str) return ''; // Handle empty or null strings
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  
  return (
    <div
      className=" dropdown menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-375px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            {imageUrl ? (
              <img src={imageUrl} onError={onError} />
            ) : (
              <div
                className={clsx(
                  "symbol-label fs-3",
                  `bg-light-${currentUser?.is_active ? "success" : "danger"}`,
                  `text-${currentUser?.is_active ? "success" : "danger"}`
                )}
              >
                {currentUser?.first_name.charAt(0)}
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
            {capitalizeFirstLetter(currentUser?.first_name)}{' '}
            {currentUser?.last_name && capitalizeFirstLetter(currentUser?.last_name)}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-8">
              {currentUser?.email}
           
            </a>
            {/* {currentUser?.role === "admin" && ( */}
            <div className="fw-bold text-muted fs-7 text-break">
              {currentUser?.customer_id}
            </div>
            {/* )} */}
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      <div className="menu-item px-5">
        <Link to={"/profile/overview"} className="menu-link px-5">
          My Profile
        </Link>
      </div>
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
