/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { SmartTable } from "../../../../sdb/layout/components/smart-table/smart-table";
import { PageTitle } from "../../../../sdb/layout/core";

export function IntegrationPage() {
  const smartTableRef: any = useRef(null);
  const { currentUser } = useAuth();
  const history = useNavigate();
  const columnDef = [
    // SDB-12:("ID" field removed)

    // {
    //   name: "Id",
    //   dataKey: "_id",
    //   className: "min-w-150px",
    //   display: "text",
    // },
    //

    // SDB6: (Name changed :"Name"--->"Module Name)
    {
      name: "Module Name",
      dataKey: "name",
      className: "min-w-150px",
      display: "text",
    },
    // SDB-13: (Name changed :"AliseName"--->"Short name)

    {
      name: "Short name",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "alise",
    },
    {
      name: "Version",
      dataKey: "meta",
      className: "min-w-150px",
      display: "arrayObject",
      objectKey: "version",
    },
    {
      display: "action",
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [{ label: "View" }],
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/viewmicrosoftsaml/add");
    } else if (data.action === "view") {
      const row = data?.rowData?.item;
      history(`/viewmicrosoftsaml/${row?._id}/view`, { state: row });
    }
  };

  return (
    <>
      {currentUser.role === "sa" ? (
        <div className="row">
          {/* <div className="d-flex flex-wrap flex-stack mb-0">
            <h3 className="fw-bolder mb-0">Modules</h3>
          </div> */}
          <PageTitle breadcrumbs={[]}>Integrations</PageTitle>
          <>
            <div style={{ display: "flex" }}>
              <div className="d-flex flex-wrap flex-stack my-0"></div>
              <div className="d-flex flex-wrap mt-5">
                <div
                  className="me-5 c-p"
                  onClick={() => {
                    history("/microsoftsaml");
                  }}
                >
                  <div className="card py-15 px-20">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        src="media/VMS.png"
                        alt="vms"
                        className="w-auto h-auto"
                      />
                      <label className="fw-bold mt-4">Microsoft-SAML</label>
                    </div>
                  </div>
                </div>
              </div>

              {/* 2 */}

              <div className="d-flex flex-wrap flex-stack my-0"></div>
              <div className="d-flex flex-wrap mt-5">
                <div
                  className="me-5 c-p"
                  onClick={() => {
                    history("/microsoftgraphapi");
                  }}
                >
                  <div className="card py-15 px-20">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <img
                        src="media/VMS.png"
                        alt="vms"
                        className="w-auto h-auto"
                      />
                      <label className="fw-bold mt-4">Microsoft-Graph API</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      ) : (
        <SmartTable
          ref={smartTableRef}
          hideActions={true}
          getURL="/integrations/"
          // deleteUrl="/modles/delete/"
          serachPlaceholder="Serach"
          addBtnText="Add"
          imagePathForNoRecord="/media/location.png"
          noRecordHelpText="Click on the below button to add your Modules"
          actionCallBack={actionCallBack}
          cols={columnDef}
          multiSelectRequired={true}
        />
      )}
    </>
  );
}
