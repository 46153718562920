import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getModulesList() {
  return axios.get<any>(`${API_URL}/modules/`);
}
export function getModuleById(mId: any) {
  return axios.get<any>(`${API_URL}/modules/${mId}/`);
}
export function getCustomersList() {
  return axios.get<any>(`${API_URL}/customers/`);
}
export function updateModule(data: any, id: string) {
  return axios.put<any>(`${API_URL}/modules/update/${id}/`, data);
}
export function deleteAccessModuleMapping(customerId: string, id: string) {
  return axios.delete<any>(
    `${API_URL}/modules/access/${id}/customer/${customerId}/`
  );
}



// SAML APIS
export function getsaml() {
  return axios.get<any>(`${API_URL}/mssaml/`);
}
export function addpostSAML(data: any) {
  return axios.post<any>(`${API_URL}/mssaml/`, data);
}

export function updateSAML(data: any, id: string) {
  return axios.put<any>(`${API_URL}/mssaml/${id}`, data);
}
export function deleteSAML(id: string) {
  return axios.delete<any>(`${API_URL}/mssaml/${id}/`);
}


// GRAPHAPI APIS
export function getgraphapi() {
  return axios.get<any>(`${API_URL}/msgraph/`);
}

export function addpostgraphapi(data: any) {
  return axios.post<any>(`${API_URL}/msgraph/`, data);
}
export function updategraphapi(data: any, id: string) {
  return axios.put<any>(`${API_URL}/msgraph/${id}`, data);
}

export function deleteGHRAPHAPI(id: string) {
  return axios.delete<any>(`${API_URL}/msgraph/${id}/`);
}








export function getCategoriesList() {
  return axios.get<any>(`${API_URL}/pantry/v1/categories/`);
}
export function getCategoriesListByCustomer(cId: any) {
  return axios.get<any>(`${API_URL}/pantry/v1/categories/customer/${cId}/`);
}