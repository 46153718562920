import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const GET_LOCATION_BY_CUSTOMER_URL = `${API_URL}/locations/customer/`;



const addLocationURL = `${API_URL}/locations/create/`;
const updateLocationURL = `${API_URL}/locations/update/`;
const deleteLocationURL = `${API_URL}/locations/delete/`;
const getBuildingsURL = `${API_URL}/buildings/`;
const addBuildingURL = `${API_URL}/buildings/create/`;
const updateBuildingURL = `${API_URL}/buildings/update/`;
const deleteBuildingURL = `${API_URL}/buildings/delete/`;
const getFloorsURL = `${API_URL}/floors/`;
const addFloorURL = `${API_URL}/floors/create/`;
const updateFloorURL = `${API_URL}/floors/update/`;

const getWingsURL = `${API_URL}/wings/`;
const addWingURL = `${API_URL}/wings/create/`;
const updateWingURL = `${API_URL}/wings/update/`;
const deletewingURL= `${API_URL}/wings/delete/`;

const getZonesURL = `${API_URL}/zones/`;
const addZoneURL = `${API_URL}/zones/create/`;
const updateZoneURL = `${API_URL}/zones/update/`;
const regiterURL = `${API_URL}/vms/v1/`;
const addDeviceURL = `${API_URL}/devices/create/`;
const getDeviceURL = `${API_URL}/devices/`;
const updateDeviceURL = `${API_URL}/devices/update/`;
const getZonesByIdURL = `${API_URL}/zones/floor/`;

// location methods
export function addLocation(data: any) {
  return axios.post<any>(addLocationURL, data);
}
export function updateLocation(data: any, id: number) {
  return axios.put<any>(`${updateLocationURL}${id}/`, data);
}
export function deleteLocation(id: number) {
  return axios.delete<any>(`${deleteLocationURL}${id}/`);
}
export function getModuleByCustomer(id: number) {
  return axios.get<any>(`${API_URL}${"/modules/customer/"}${id}/`);
}
export function getLocationByCustomer(id: number) {
  return axios.get<any>(`${GET_LOCATION_BY_CUSTOMER_URL}${id}/`);
}






// building methods
export function addBuilding(data: any) {
  return axios.post<any>(addBuildingURL, data);
}
export function updateBuilding(data: any, id: number) {
  return axios.put<any>(`${updateBuildingURL}${id}/`, data);
}
export function deleteBuilding(id: number) {
  return axios.delete<any>(`${deleteBuildingURL}${id}/`);
}
export function getBuildings() {
  return axios.get<any>(getBuildingsURL);
}

// Floors methods
export function addFloor(data: any) {
  return axios.post<any>(addFloorURL, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getFloors() {
  return axios.get<any>(getFloorsURL);
}

export function updateFloor(data: any, id: number) {
  return axios.put<any>(`${updateFloorURL}${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}


// Wings methods
export function addWing(data: any) {
  return axios.post<any>(addWingURL, data);

}

export function getWings() {
  return axios.get<any>(getWingsURL);
}

export function updateWing(data: any, id: number) {
  return axios.put<any>(`${updateWingURL}${id}/`, data);
}


export function deletewing(id: number) {
  return axios.delete<any>(`${deletewingURL}${id}/`);
}







// Zone methods
export function getZones() {
  return axios.get<any>(getZonesURL);
}

export function addZone(data: any) {
  return axios.post<any>(addZoneURL, data);
}

export function updateZone(data: any, id: number) {
  return axios.put<any>(`${updateZoneURL}${id}/`, data);
}

export function addPublicUser(data: any) {
  return axios.post<any>(`${regiterURL}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updatePublicUser(data: any, visitId: string) {
  return axios.put<any>(`${API_URL}/vms/v1/visit/${visitId}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getZonesByFloorId(fid: any) {
  return axios.get<any>(`${getZonesByIdURL}${fid}/`);
}
// Device methods

export function getDevice() {
  return axios.get<any>(getDeviceURL);
}
export function addDevice(data: any) {
  return axios.post<any>(addDeviceURL, data);
}
export function updateDevice(data: any, id: number) {
  return axios.put<any>(`${updateDeviceURL}${id}/`, data);
}
export function getDeviceByFloor(floorId) {
  return axios.get<any>(`${API_URL}/devices/floor/${floorId}/`);
}
export function getDeviceByZone(zoneId) {
  return axios.get<any>(`${API_URL}/devices/zone/${zoneId}/`);
}
export function getLicenseInfoByCustomerId(customerId) {
  return axios.get<any>(`${API_URL}/licences/customer/${customerId}/`);
}
export function addLicenseInfo(data) {
  return axios.post<any>(`${API_URL}/licences/create/`, data);
}
export function updateLicenseInfo(data, id) {
  return axios.put<any>(`${API_URL}/licences/update/${id}/`, data);
}
