/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  getsingleUsergroup,
  enableModulesAccess,
} from "../../../pages/Tenants/requests";
import { useAuth } from "../../auth";
import Select from "react-select";
import { useLocation } from "react-router-dom";

export function AccessControl() {
  const [userGroupList, setUserGroupList] = useState([]);
  const { currentUser } = useAuth();
  const [value, setValue] = useState<any>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const getUsergroup = async (customerId: number) => {
    try {
      const { data } = await getsingleUsergroup(customerId);
      if (data) {
        const groupData = data.map((item: any) => {
          return {
            ...item,
            id: item._id,
          };
        });
        groupData.forEach((element: any) => {
          element["value"] = element.id;
          element["label"] = element.name;
        });
        setUserGroupList(groupData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveUserGroupAccess = async () => {
    try {
      let data = {
        customer_id: currentUser.customer_id,
        user_group: value,
      };
      enableModulesAccess(data, id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsergroup(currentUser.customer_id);
  }, []);

  return (
    <>
      <div className="row">
        <h2 className="fw-bolder my-2">Usergroup Access</h2>
        <div className="col-12 mb-7">
          <Select
            isMulti
            name="usergroup"
            options={userGroupList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOptions) => {
              const selectedIds = selectedOptions.map(
                (option: any) => option.id
              );
              setValue(selectedIds);
            }}
          />
          <div className="text-end mt-7">
            <button
              className="btn btn-primary"
              style={{ marginLeft: "12px" }}
              onClick={(e) => {
                saveUserGroupAccess();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
