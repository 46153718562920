/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { resetPassword } from "../core/_requests";

const initialValues = {};

const verifyOTPSchema = Yup.object().shape({});

export function VerifyOTP() {
  // Get the current URL
  const currentUrl = window.location.href;

  // Create an URL object to parse the URL
  const urlObject = new URL(currentUrl);

  // Get the token from the "token" query parameter
  const token = urlObject.search.slice(1);
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [securityCode, setSecurityCode] = useState(new Array(6));
  const formik = useFormik({
    initialValues,
    validationSchema: verifyOTPSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setHasErrors(undefined);
      setTimeout(() => {
        resetPassword(securityCode.join(""), token)
          .then(({ data: { result } }) => {
            setHasErrors(false);
            setLoading(false);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus("The otp is incorrect");
          });
      }, 1000);
    },
  });

  // Function to handle auto-tabbing
  const handleAutoTab = (event, nextInputRef) => {
    const maxCharacters = parseInt(event.target.getAttribute("maxlength"));
    const currentLength = event.target.value.length;
    if (
      currentLength === maxCharacters &&
      event.target.value === event.target.defaultValue
    ) {
      // nextInputRef.current.focus();
      document.getElementById(nextInputRef)?.focus();
    }
  };

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_otp_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-10">
        {/* begin::Title */}
        <h1 className="text-dark fw-bolder mb-3">OTP Verification</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className="text-gray-500 fw-semibold fs-6">
          Enter the verification code we sent to{" "}
          <span className="fw-bold text-black">admin@gmail.com</span>
        </div>
        {/* end::Link */}
      </div>
      {/* begin::Title */}
      {hasErrors === true && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">
            Sorry, looks like there are some errors detected, please try again.
          </div>
        </div>
      )}
      {/* end::Title */}
      {/* begin::Form group */}
      <div className="fv-row mb-3">
        <label className="form-label fw-bolder text-dark fs-6 mb-0">
          Type your 6 digit security code
        </label>
        <div className="d-flex gap-6 justify-content-between my-3">
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="first"
            value={securityCode[0]}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[0] = e.target.value;
              setSecurityCode(items);
            }}
            onKeyUp={(e) => {
              handleAutoTab(e, "second");
            }}
            maxLength={1}
          />
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="second"
            value={securityCode[1]}
            onKeyUp={(e) => {
              handleAutoTab(e, "third");
            }}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[1] = e.target.value;
              setSecurityCode(items);
            }}
            maxLength={1}
          />
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="third"
            onKeyUp={(e) => {
              handleAutoTab(e, "fourth");
            }}
            maxLength={1}
            value={securityCode[2]}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[2] = e.target.value;
              setSecurityCode(items);
            }}
          />
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="fourth"
            maxLength={1}
            value={securityCode[3]}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[3] = e.target.value;
              setSecurityCode(items);
            }}
            onKeyUp={(e) => {
              handleAutoTab(e, "fifth");
            }}
          />
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="fifth"
            maxLength={1}
            value={securityCode[4]}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[4] = e.target.value;
              setSecurityCode(items);
            }}
            onKeyUp={(e) => {
              handleAutoTab(e, "sixth");
            }}
          />
          <input
            className="form-control form-control-border-solid mb-3 mb-lg-0 text-center"
            type="text"
            id="sixth"
            maxLength={1}
            value={securityCode[5]}
            onChange={(e) => {
              let items: any = [...securityCode];
              items[5] = e.target.value;
              setSecurityCode(items);
            }}
          />
        </div>
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className="d-flex flex-wrap justify-content-center mt-15 pb-lg-0">
        <button
          type="submit"
          id="kt_password_reset_submit"
          className="btn btn-primary"
        >
          <span className="indicator-label">Submit</span>
          {loading && (
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
      <div className="mt-5">
        <div className="d-flex flex-wrap justify-content-center ">
          <span>
            {" "}
            Didn’t get the code?{" "}
            <a className="text-primary fw-bold" href="javascript:;">
              Resend
            </a>{" "}
            or{" "}
            <a className="text-primary fw-bold" href="javascript:;">
              Call Us
            </a>
          </span>
        </div>
      </div>
    </form>
  );
}
