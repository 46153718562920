import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getAction(url: string) {
  return axios.get<any>(`${API_URL}${url}`);
}
export function deleteAction(url: string) {
  return axios.delete<any>(`${API_URL}${url}`);
}
export function deleteusers(items) {
  return axios.post<any>(`${API_URL}/users/delete/bulk/`, items);
}
export function deleteLocations(url: string, items: any, method?) {
  if (method === 'DELETE') {
    return axios.delete<any>(`${API_URL}${url}`, items);

  }
  else {
    return axios.post<any>(`${API_URL}${url}`, items);

  }
}
