import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../sdb/helpers";


const API_URL = process.env.REACT_APP_API_URL;
// SDB:[ADDED URL for customer admin usergroup]
const GET_USERGROUP_BY_CUSTOMER_URL = `${API_URL}/user-group/customer_id/`;



export function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
// location methods
export function addCustomer(data: any) {
  return axios.post<any>(`${API_URL}/customers/create/`, data);
}
export function updateCustomer(data: any, id: number) {
  return axios.put<any>(`${API_URL}/customers/update/${id}/`, data);
}
export function getEndpoints() {
  return axios.get<any>(`${API_URL}/endpoints/`);
}


export function getCustomers() {
  return axios.get<any>(`${API_URL}/customers/`);
}


export function addUsergroup(data: any) {
  return axios.post<any>(`${API_URL}/user-group/create/`, data);
}

export function updateUsergroup(data: any, id: number) {
  return axios.put<any>(`${API_URL}/user-group/update/${id}/`, data);
}

export function getsingleUsergroup(id: number) {
  return axios.get<any>(`${API_URL}/user-group/customer_id/${id}/`);
}

// SDB:[ADDED FUNCTION for customer admin usergroup]

export function getUsergroupbycid(id: number) {
  return axios.get<any>(`${GET_USERGROUP_BY_CUSTOMER_URL}${id}/`);
}


export function enableModulesAccess(data: any, id: any) {
  return axios.put<any>(`${API_URL}/modules/access/${id}/`, data);
}
export function addCustomerAdmin(data: any) {
  return axios.post<any>(`${API_URL}/users/create/`, data);
}
export function updateCustomerAdmin(email: any) {
  return axios.put<any>(`${API_URL}/users/update/admin/${email}/`);
}
export function deleteCustomerAdmin(email: any) {
  return axios.delete<any>(`${API_URL}/users/delete/admin/${email}/`);
}


const getUserById = (id: ID): Promise<any | undefined> => {
  return axios
    .get(`${API_URL}/users/${id}/`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => {
      return response;
    });
};
export {
  getUserById
}