/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SmartTable } from "../../../sdb/layout/components/smart-table/smart-table";
import { PageTitle } from "../../../sdb/layout/core";
import './tenants.css'; // Import your CSS file
export function Tenants() {
  const history = useNavigate();
  const smartTableRef: any = useRef(null);
  const [showModal, setModalView] = useState(false);
  const [selectedRecords, setActiveRecords] = useState({});

  const columnDef = [
    // SDB6: (Name changed :"ID"--->"Customer ID")

    {
      name: "Tenant ID",
      dataKey: "_id",
      className: "min-w-150px",
      display: "text",
      sortable: true, 
      sortDirection: "asc",
    },

    // SDB6: (Name changed :"Customer Name"--->"Tenant name")
    {
      name: "Tenant name",
      dataKey: "name",
      className: "min-w-150px ",
      display: "text",
      sortable: true, 
      sortDirection: "asc",
    },
    // SDB6: (Name changed :"Address"--->"Teant Address")

    {
      name: "Tenant Address",
      dataKey: "address",
      className: "min-w-200px",
      display: "text",
      sortable: true, 
      sortDirection: "asc", 
    },
    // SDB6: (Name changed :"Contact"--->"Tenant Contact")

    // {
    //   name: "Tenant Contact",
    //   dataKey: "",
    //   className: "min-w-150px",
    //   display: "text",
    // },

    // SDB7: ( New fields are created :"Tenant Contact person","Tenant Contact Email","Tenant Contact phone number")
    {
      name: "Tenant Contact person",
      dataKey: "contact",
      className: "min-w-200px",
      display: "text",
      sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "Tenant Contact Email",
      dataKey: "contact_email",
      className: "min-w-200px",
      display: "text",
      sortable: true,
      sortDirection: "asc", 
      cellRenderer: (value: string) => value.toLowerCase(), 
      
    },
    {
      name: "Tenant Contact number",
      dataKey: "contact_phone",
      className: "tenantc_number",
      display: "",
      sortable: true, 
      sortDirection: "asc", 

    },

    {
      name: "Status",
      dataKey: "is_active",
      className: "min-w-90px",
      display: "status",
      
    },
    {
      name: "Action",
      dataKey: "action",
      // className: "min-w-90px",
      actions: [
        { label: "Access" },
        { label: "Licenses" },
        { label: "Edit" },
        { label: "Delete" },
      ],
      display: "action",
    },
  ];

  const actionCallBack = (data: any) => {
    if (data.action === "add") {
      history("/tenants/add");
    } else if (data.action === "edit") {
      const row = data.rowData.item;
      history(`/tenants/${row._id}/manage`, { state: row });
    } else if (data.action === "access") {
      const row = data.rowData.item;
      history(`/tenants/${row._id}/access`, { state: row });
    } else if (data.action === "Licenses") {
      const row = data.rowData.item;
      history(`/tenants/${row._id}/licenses`, { state: row });
    }
  };
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-0">
        {/* // SDB6: (Name changed :"Customers"--->"Tenants") */}

        <PageTitle breadcrumbs={[]}>Tenants</PageTitle>
      </div>
      <div className="row">
        <SmartTable
          ref={smartTableRef}
          getURL="/customers/"
          deleteUrl="/customers/delete/"
          bulkdelete="/customers/delete/bulk/"
          serachPlaceholder="Search"
          // SDB: (Name changed :"Add new customer"--->"Add New Tenant")
          addBtnText="Add New Tenant"
          imagePathForNoRecord="/media/location.png"
          // SDB6: (Name changed :"Click on the below button to add your Customer"--->"Click on the below button to add your Tenant")
          noRecordHelpText="Click on the below button to add your Tenant"
          actionCallBack={actionCallBack}
          cols={columnDef}
          multiSelectRequired={true}
        />
      </div>
    </>
  );
}