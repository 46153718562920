import React, { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { PageTitle } from "../../../sdb/layout/core";
import { SmartTable } from "../../../sdb/layout/components/smart-table/smart-table";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../app/modules/auth";
import { useLocation } from "react-router-dom";

const ReportsWrapper: FC = () => {
  const location: any = useLocation();
  const { currentUser, logout } = useAuth();
  const cId= currentUser.customer_id;
  const locationParams: any = location.state;
  const history = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const { id } = useParams(); // Extracting the customer ID from URL params
  const intl = useIntl();
  const navigate = useNavigate();
  const smartTableRef: any = useRef(null);
  const [showModal, setModalView] = useState(false);
  const [selectedRecords, setActiveRecords] = useState({});
  const [sortColumn, setSortColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  // Define the getURL based on the user's role
  let getURL = currentUser?.role === "user" || currentUser?.role === "sa" ? "/logs/" : `/logs/customer/${cId}`; 

  const columnDef = [
    {
      name: "Timestamp",
      dataKey: "meta",
      className: "min-w-250px",
      display: "object",
      objectKey: "timestamp",
      sortable: true, 
      sortDirection: "asc", 
    },
    {
      name: "ItemType",
      dataKey: "meta",
      className: "min-w-150px",
      display: "object",
      objectKey: "item_type",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "ItemName",
      dataKey: "meta",
      className: "min-w-150px",
      display: "object",
      objectKey: "item_name",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "User",
      dataKey: "meta",
      className: "min-w-120px",
      display: "object",
      objectKey: "user",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "Type",
      dataKey: "meta",
      className: "min-w-100px",
      display: "object",
      objectKey: "type",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "Status",
      dataKey: "meta",
      className: "min-w-90px",
      display: "object",
      objectKey: "status",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "Details",
      dataKey: "",
      className: "min-w-100px",
      display: "",  sortable: true, 
      sortDirection: "asc",
    },
    {
      name: "Action",
      dataKey: "action",
      className: "min-w-90px",
      actions: [
        // { label: "Access" },
        // { label: "Edit" },
        { label: "Delete" },
        // { label: "Licenses" },
      ],
      display: "action",
    },
  ];
  const handleSort = (column: any) => {
    if (column.sortable) {
      // Toggle sort direction if the same column is clicked again
      const newSortDirection = sortColumn === column.dataKey ? (sortDirection === "asc" ? "desc" : "asc") : "asc";
      setSortColumn(column.dataKey);
      setSortDirection(newSortDirection);
    }
  };
  return (
    <>
      <PageTitle breadcrumbs={[]}>{"Reports"}</PageTitle>

      <div className="d-flex flex-wrap flex-stack mb-6">
        <PageTitle breadcrumbs={[]}>Audit Reports</PageTitle>
      </div>

      <div className="row" style={{ marginTop: "-3vh" }}>
        <SmartTable
          ref={smartTableRef}
          getURL={getURL}
          hideActions={true}
          hidesearch={true}
          hideFilters={true}
          deleteUrl="/logs/customer/"
          bulkdelete="/reports/delete/bulk/"
          cols={columnDef.map(col => ({ ...col, sortDirection: col.dataKey === sortColumn ? sortDirection : "" }))}
          multiSelectRequired={false}
          onSort={handleSort}
        />
      </div>
    </>
  );
};

export { ReportsWrapper };