import { Field, useFormik, FormikProvider } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResponsivePagination from "react-responsive-pagination";
import { KTIcon } from "../../../sdb/helpers";
import "./publicurlvms.css";
import { useListView } from "./user-management/users-list/core/ListViewProvider";
import {
  getsingleUsergroup,
  enableModulesAccess,
} from "../../pages/Tenants/requests";
import {
  getZones,
  getCategoriesList,
  getlocationlist,
} from "../apps/pantry-management/requests";
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import './main__.css'
import {
  addSetupDataByCustomer,
  approveReject,
  getSetupDataByCustomer,
  getVisitorData,
  getVisitorDataByHost,
  getVisitorDataByLocation,
  updateSetupDataByCustomer,
  getexpiredstatus,
} from "./request";
import { useAuth } from "../auth";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { VisitorInfo } from "./view-visitor-info";
import AddVisitorInfo from "./add-visitor-info";
import { useDebouncedCallback } from "../../utils";
import { Console } from "console";
import { PendingVMS } from "./pendingrequest";
import { Yettocheckin } from "./yettocheckin";
import { FacilityApproval } from "./facilityapproval";
import { ActiveView } from "./activecheckin";
import { History } from "./history";

const editUserSchema = Yup.object().shape({
  // duration: Yup.string().required("Duration is required"),
  identification_type: Yup.string().required("Identification type is required"),
  visitor_type: Yup.string().required("Visitor type is required"),
  purpose_of_visit: Yup.string().required("purpose ofvisit is required"),
});

export function VisitorManagementPage() {
  const { currentUser, logout } = useAuth();
  const { isAllSelected, onSelectAll } = useListView();
  const { selected, onSelect } = useListView();
  const isSelected = false;
  const [setupData, setSetupData] = useState(null);
  const [activeView, setActiveView] = useState(
    currentUser.role === "admin" ? "setup" : "pending"
  );
  const [filterVisitorDataList, setFilterVisitorDataList] = useState([]);
  const [visitorDataList, setVisitorDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  // const [currentPage_1, setCurrentPage_1] = useState(10);

  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = visitorDataList.slice(startIndex, endIndex);
  const [locationlist, setlocationlist] = useState([]);

  const locSet = locationlist.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");
  const onSearch = useDebouncedCallback(() => {
    let newCategoryList = [...filterVisitorDataList];
    if (newCategoryList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newCategoryList = newCategoryList.filter(
          (x: any) =>
            x.host_name.toLowerCase().includes(iText) ||
            x.visitor_profile.email.toLowerCase().includes(iText) ||
            x.visitor_profile.full_name.toLowerCase().includes(iText) ||
            (x.visit_data &&
              x.visit_data.purpose_of_visit &&
              x.visit_data.purpose_of_visit.toLowerCase().includes(iText))
        );
      }
      setVisitorDataList(newCategoryList);
      setlocationlist(newCategoryList);

      setTotalPages(
        Math.ceil(newCategoryList.length || locationlist.length / itemsPerPage)
      );
      // setTotalPages1(Math.ceil(locationlist.length / itemsPerPage));
    }
  }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const [viewModal, setModalData] = useState(false);
  const [showAddVisitModal, setShowAddVisitModal] = useState(false);
  const [userGroupList, setUserGroupList] = useState([]);
  const [value, setValue] = useState<any>([]);
  const [userGroupAccess, setuserGroupAccess] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const divRef = useRef<HTMLDivElement | null>(null);
  const addClass = () => {
    setShowAddVisitModal(true);
    divRef.current?.classList.add("main-div");
  };

  const removeClass = () => {
    divRef.current?.classList.remove("main-div");
  };

  const initialValues = {
    customer_id: "",
    location_id: "",
    // duration: "",
    purpose_of_visit: "",
    identification_type: "",
    visitor_type: "",
  };
  const formik: any = useFormik({
    initialValues,
    validationSchema: editUserSchema,
    onSubmit: (values: any) => {
      if (values._id) {
        updateSetupDataByCustomer(values, values._id)
          .then((daa: any) => {
            Swal.fire("Configuration Setup Saved!", "", "success");
          })
          .catch((error: any) => {
            Swal.fire(
              "Data setup failed!",
              error.response.data.detail,
              "error"
            );
          });
      } else {
        values["customer_id"] = currentUser.customer_id;
        values["location_id"] = currentUser.location_id
          ? currentUser.location_id[0]
          : null;

        addSetupDataByCustomer(values)
          .then((daa: any) => {
            Swal.fire("Configuration Setup Saved!", "", "success");
          })
          .catch((error: any) => {
            Swal.fire(
              "Data setup failed!",
              error.response.data.detail,
              "error"
            );
          });
      }
    },
  });

  // // Get Update Expired Status start
  // const getexpireddata = async (customer_id: string) => {
  //   try {
  //     const { data } = await getexpiredstatus(customer_id);
  //     if (data) {
  //       formik.setValues(data);
  //       setSetupData(data);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // // Get Update Expired Status end

  const getSetupData = async (customer_id: string) => {
    try {
      const { data } = await getSetupDataByCustomer(customer_id);
      if (data) {
        formik.setValues(data);
        setSetupData(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVisitorDataByLocationInfo = async (locationId: string) => {
    try {
      const { data } = await getVisitorDataByLocation(locationId, 0, 20);
      if (data) {
        setVisitorDataList(data);
        setFilterVisitorDataList(data);

        data.forEach((item) => {
          if (item.visit_data.status === "Checked In") {
          }
        });

        // Calculate total pages based on the length of the data array
        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getVisitorDatas = async (customerId: string) => {
    try {
      const { data } = await getVisitorData(customerId, 0, 20);
      if (data) {
        setVisitorDataList(data);
        setFilterVisitorDataList(data);
        // console.log(data);

        setTotalPages(Math.ceil(data.length / itemsPerPage));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getVisitorInfoByHost = async () => {
    try {
      const { data } = await getVisitorDataByHost(0, 20);
      if (data) {
        setVisitorDataList(data);
        setFilterVisitorDataList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        // console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const saveUserGroupAccess = async () => {
    try {
      let data = {
        customer_id: currentUser.customer_id,
        user_group: userGroupAccess,
      };
      enableModulesAccess(data, id);
    } catch (error) {
      console.error(error);
    }
  };
  const getUsergroup = async (customerId: number) => {
    try {
      const { data } = await getsingleUsergroup(customerId);
      if (data) {
        const groupData = data.map((item: any) => {
          return {
            ...item,
            id: item._id,
          };
        });
        groupData.forEach((element: any) => {
          element["value"] = element.id;
          element["label"] = element.name;
        });
        setUserGroupList(groupData);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const approveRejectUser = async (item: any, flag: boolean) => {
    const updateURL =
      currentUser.role === "facility"
        ? `vms/v1/facility/visit/${item.visit_data._id}/`
        : `vms/v1/host/visit/${item.visit_data._id}/`;
    const postData =
      currentUser.role === "facility"
        ? {
            meta: {
              admin_approval: flag,
            },
          }
        : {
            meta: {
              host_approval: flag,
            },
          };
    try {
      const { data } = await approveReject(updateURL, postData);
      if (data) {
        getVisitorInfo();
        Swal.fire(
          flag ? "Approved" : "Rejected",
          `Request has been ${flag ? "approved" : "rejected"} succefully!`,
          "success"
        );
      }
    } catch (error) {
      console.error(error);
      Swal.fire(
        flag ? "Approved" : "Rejected",
        `Failed to ${flag ? "approved" : "rejected"} user!`,
        "error"
      );
    }
  };
  const getVisitorInfo = () => {
    if (currentUser.role === "facility") {
      getVisitorDataByLocationInfo(
        currentUser.location_id ? currentUser.location_id[0] : null
      );
    } else if (currentUser.role === "user") {
      getVisitorInfoByHost();
    } else {
      const intervalId = setInterval(() => {
        getVisitorDatas(currentUser.customer_id);
      }, 900000);
      // CHANGES HERE FOR CUSTOEMR
      return () => clearInterval(intervalId);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getVisitorInfo();
    }, 30000);
    setModalData(false);
    setShowAddVisitModal(false);
    /*  setSelectedItem(null); */
    getSetupData(currentUser.customer_id);
    getVisitorInfo();
    getUsergroup(currentUser.customer_id);
  }, []);

  // part -2

  const history = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [filterCategoryList, setFilterCategoryList] = useState([]);

  // const onSearch = useDebouncedCallback(() => {
  //   let newCategoryList = [...filterCategoryList];
  //   if (newCategoryList.length > 0) {
  //     if (searchText) {
  //       const iText = searchText.toLowerCase();
  //       newCategoryList = newCategoryList.filter(
  //         (x: any) =>
  //           x.name.toLowerCase().includes(iText) ||
  //           x.description.toLowerCase().includes(iText)
  //       );
  //     }
  //     setCategoryList(newCategoryList);
  //     setTotalPages(Math.ceil(newCategoryList.length / itemsPerPage));
  //   }
  // }, 500);

  useEffect(() => {
    onSearch();
  }, [searchText]);

  const getCategory = async () => {
    try {
      const { data } =
        currentUser.role === "admin"
          ? await getZones()
          : await getCategoriesList();
      if (data) {
        setCategoryList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        setFilterCategoryList(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  // const [zones, setZones] = useState<any[]>([]);

  // useEffect(() => {
  //   const fetchZones = async () => {
  //     try {
  //       const { data } = await getZones();
  //       if (data) {
  //         setZones(data);
  //         console.log(data);

  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchZones();
  // }, []);

  // const [locations, setLocations] = useState<any[]>([]);

  useEffect(() => {
    const fetchlocations = async () => {
      try {
        const { data } = await getlocationlist();
        if (data) {
          setlocationlist(data);
          // console.log(data);
          // setTotalPages(Math.ceil(data.length / itemsPerPage));
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchlocations();
  }, []);

  const generateLocationEncodedString = (location) => {
    if (location) {
      const { _id, customer_id, name } = location;
      const query_string = `vms&cId=${customer_id}&lid=${_id}`;
      return btoa(query_string);
    }

    return "";
  };

  useEffect(() => {}, []);

  const [copiedUrl, setCopiedUrl] = useState("");

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedUrl(text);

    setTimeout(() => {
      setCopiedUrl("");
    }, 2000);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-0">
            <div
              id="kt_app_toolbar_container"
              className="app-container-fluid  d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  Visitor Management
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-0">
                  <li className="breadcrumb-item text-mutedforbreadcrumb ">
                    <a
                      href="/modules"
                      className="text-muted text-hover-primary"
                    >
                      SDB Services
                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>

                  <li className="breadcrumb-item text-muted">
                    Visitor Management
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex overflow-auto h-55px px-0">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              {currentUser.role === "admin" && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("setup")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "setup" && "active")
                    }
                  >
                    Setups
                  </a>
                </li>
              )}{" "}
              {/* {currentUser.role === "admin" && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("data")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "data" && "active")
                    }
                  >
                    Data
                  </a>
                </li>
              )}{" "} */}
              {/* SDB-240724[Pending Request tab name changed -"Host Approval"] */}
              {(currentUser.role === "facility" ||
                currentUser.role === "admin" ||
                currentUser.role === "user") && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("pending")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "pending" && "active")
                    }
                  >
                    Host Approval
                  </a>
                </li>
              )}
              {/* SDB-240724[New tabs are Added "Facility Approval"] */}
              {(currentUser.role === "facility" ||
                currentUser.role === "admin" ||
                currentUser.role === "user") && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("FacilityApproval")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "FacilityApproval" && "active")
                    }
                  >
                    Facility Approval
                  </a>
                </li>
              )}
              {/* SDB-[New tabs are created "Yet to Check-In ,History "] */}
              {(currentUser.role === "facility" ||
                currentUser.role === "admin" ||
                currentUser.role === "user") && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("yet")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "yet" && "active")
                    }
                  >
                    Yet to Check-In
                  </a>
                </li>
              )}
              {(currentUser.role === "facility" ||
                currentUser.role === "admin" ||
                currentUser.role === "user") && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("Check_in")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "Check_in" && "active")
                    }
                  >
                    Active Check In
                  </a>
                </li>
              )}
              {(currentUser.role === "facility" ||
                currentUser.role === "admin" ||
                currentUser.role === "user") && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("history")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "history" && "active")
                    }
                  >
                    History{" "}
                  </a>
                </li>
              )}
              {currentUser.role === "admin" && (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("publicurl")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "publicurl" && "active")
                    }
                  >
                    Public URL
                  </a>
                </li>
              )}
              {/* {currentUser.role === "facility" ||
              currentUser.role === "user" ? (
                ""
              ) : (
                <li className="nav-item">
                  <a
                    onClick={() => setActiveView("access")}
                    className={
                      `nav-link text-active-primary me-6 c-p ` +
                      (activeView === "access" && "active")
                    }
                  >
                    Access
                  </a>
                </li>
              )} */}
              {currentUser.role === "user" && (
                <li
                  className="nav-item"
                  style={{ position: "absolute", right: "1cm" }}
                >
                  <button
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#visitorInfoModal"
                    className="btn btn-primary me-3"
                    onClick={
                      () => {
                        setShowAddVisitModal(true);
                        divRef.current?.classList.add("main-div");
                      } /* addClass */
                    }
                  >
                    <KTIcon iconName="plus" className="fs-3 btn-light" />
                    Visit Schedular
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div id="kt_app_content_container" className="app-container-fluid">
          <div className="row mt-5">
            <div className="col-sm-12">
              {activeView === "setup" && currentUser.role === "admin" ? (
                <div className="card">
                  <div className="card-body">
                    <FormikProvider value={formik}>
                      <form
                        className="form"
                        onSubmit={formik.handleSubmit}
                        noValidate
                      >
                        <div className="row">
                          {/* <div className="col-6">
                            <label className="form-label ">Duration</label>
                            <Field
                              as="textarea"
                              {...formik.getFieldProps("duration")}
                              name="duration"
                              className="form-control form-control-lg form-control-solid"
                              rows={3}
                              // disabled
                            ></Field>
                          </div> */}

                          <div className="col-6">
                            <label className="form-label Required ">
                              Purpose of visit
                            </label>
                            <Field
                              as="textarea"
                              {...formik.getFieldProps("purpose_of_visit")}
                              name="purpose_of_visit"
                              className="form-control form-control-lg form-control-solid"
                              rows={3}
                              // disabled
                            ></Field>
                          </div>

                          <div className="col-6">
                            <label className="form-label required">
                              Identification
                            </label>
                            <Field
                              as="textarea"
                              {...formik.getFieldProps("identification_type")}
                              name="identification_type"
                              className="form-control form-control-lg form-control-solid"
                              rows={3}
                            ></Field>
                          </div>

                          <div className="col-6">
                            <label className="form-label required">
                              Visitor Type
                            </label>
                            <Field
                              as="textarea"
                              {...formik.getFieldProps("visitor_type")}
                              name="visitor_type"
                              className="form-control form-control-lg form-control-solid"
                              rows={3}
                            ></Field>
                          </div>
                        </div>
                        <div className="fv-row mb-10 mt-6">
                          <div>
                            <button
                              type="submit"
                              //       disabled={
                              //   formik.isSubmitting ||
                              //   !formik.isValid ||
                              //   !formik.touched
                              // }
                              className="btn btn-lg btn-primary me-3 "
                            >
                              <span className="indicator-label">Save</span>
                            </button>
                          </div>
                        </div>
                      </form>
                    </FormikProvider>
                  </div>
                </div>
              ) : (
                ""
              )}

              {activeView === "pending" && <PendingVMS />}

              {activeView === "FacilityApproval" && <FacilityApproval />}

              {activeView === "publicurl" && (
                <div className="card card-flush my-5">
                  <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          value={searchText}
                          data-kt-ecommerce-product-filter="search"
                          className="form-control form-control-solid w-250px ps-12"
                          placeholder="Search "
                          onChange={(e) => {
                            const value = e.target.value;
                            setSearchText(value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {/* 2nd part start */}
                  <div className="card-body pt-0">
                    <div className="table-responsive">
                      <table
                        className="table align-middle table-row-dashed fs-6 gy-5"
                        id="kt_ecommerce_products_table"
                      >
                        <thead>
                          <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                            <th className="w-10px pe-2">
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  data-kt-check="true"
                                  data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                                  value="1"
                                />
                              </div>
                            </th>
                            <th className="min-w-250px">Name</th>
                            <th className="min-w-150px">URL</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                          {locSet.map((item: any, i) => {
                            const location = locationlist[i];
                            const encodedString =
                              generateLocationEncodedString(location);
                            const baseURL = `${window.location.host}/vms/home?q=`;

                            return (
                              <tr key={i}>
                                <td>
                                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={item.id}
                                    />
                                  </div>
                                </td>
                                <td>{item.name}</td>
                                <td className="pe-0">
                                  <div title={`${baseURL}${encodedString}`}>
                                    {`${baseURL}${encodedString.substring(
                                      0,
                                      50
                                    )}...`}
                                  </div>
                                  {encodedString.length > 50 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-light btn-icon btn-icon-primary ms-2"
                                        onClick={() =>
                                          handleCopy(
                                            `${baseURL}${encodedString}`
                                          )
                                        }
                                        title="Copy to clipboard"
                                      >
                                        <i className="bi bi-clipboard"></i>
                                      </button>
                                      {copiedUrl ===
                                        `${baseURL}${encodedString}` && (
                                        <span style={{ marginLeft: "8px" }}>
                                          Your URL is copied
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="row mt-2">
                      <div className="col-1">
                        <select
                          name="records_per_age"
                          className="form-select form-select-lg form-select-solid w-auto"
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setItemsPerPage(value);
                            // setTotalPages_1
                            setTotalPages(
                              Math.ceil(locationlist.length / value)
                            );
                          }}
                          value={itemsPerPage}
                        >
                          {new Array(5).fill(null).map((i, index) => {
                            const value = 10 * (index + 1);
                            return (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-11">
                        {
                          <ResponsivePagination
                            total={1}
                            current={currentPage}
                            onPageChange={(page) => handlePageChange(page)}
                            // onPageChange={(page)=>}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeView === "yet" && <Yettocheckin />}

              {activeView === "Check_in" && <ActiveView />}

              {/* Actiev  checkin  end */}

              {activeView === "history" && <History />}

              {activeView === "data" && (
                <div className="card">
                  <div className="card-header border-0 pt-6">
                    <div className="card-title">
                      <div className="d-flex align-items-center position-relative my-1">
                        <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                          <span className="path1"></span>
                          <span className="path2"></span>
                        </i>
                        <input
                          type="text"
                          data-kt-user-table-filter="search"
                          className="form-control form-control-solid w-250px ps-14"
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => {
                            const value = e.target.value;
                            setSearchText(value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-end"
                        data-kt-user-table-toolbar="base"
                      >
                        <button
                          type="button"
                          className="btn btn-light-primary me-3"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          <i className="ki-duotone ki-filter fs-2">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          Filter
                        </button>
                        {/*     <button
                      type="button"
                      className="btn btn-light-primary me-3"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                    >
                      <i className="ki-duotone ki-filter fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                      ADD VISIT
                    </button> */}

                        {/* doubt here */}
                        {currentUser.role === "user" && (
                          <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#visitorInfoModal"
                            className="btn btn-light-primary me-3"
                            onClick={
                              () => {
                                setShowAddVisitModal(true);
                                divRef.current?.classList.add("main-div");
                              } /* addClass */
                            }
                          >
                            {/* <i className="ki-duotone ki-filter fs-2">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i> */}
                            ADD
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th>
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  data-kt-check={isAllSelected}
                                  data-kt-check-target="#kt_table_users .form-check-input"
                                  checked={isAllSelected}
                                  onChange={onSelectAll}
                                />
                              </div>
                            </th>
                            {/* <th>Visiter Id</th> */}
                            {/* SDB-[Fields nmes are changed-"Username->Visitor Name,Email ID->Visitor Email ID,Host name->Employee Name"] */}
                            <th className="min-w-150px">Visitor Name</th>
                            <th className="min-w-150px">Visitor Email ID</th>
                            <th className="min-w-150px">Employee Name</th>
                            <th className="min-w-150px">Visiting date</th>
                            <th className="min-w-150px">Visiting Time</th>
                            <th className="min-w-150px">Purpose of visit</th>
                            <th className="min-w-150px">Access token</th>
                            <th className="min-w-150px">Sign-in Time</th>
                            <th className="min-w-150px">Sign-out Time</th>
                            <th className="min-w-90px">Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-bold">
                          {dataSet.map((item: any, i) => {
                            if (
                              item &&
                              item.visitor_profile &&
                              item.visitor_profile.full_name &&
                              item.visitor_profile.email
                            ) {
                              return (
                                <tr role="row" key={i}>
                                  <td>
                                    <div className="form-check form-check-custom form-check-solid">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        data-kt-check={isSelected}
                                        data-kt-check-target="#kt_table_users .form-check-input"
                                        checked={isSelected}
                                        onChange={() => onSelect(10)}
                                      />
                                    </div>
                                  </td>
                                  {/* <td>{item.visit_data.visitor_id}</td> */}
                                  <td>{item.visitor_profile.full_name}</td>
                                  <td>{item.visitor_profile.email}</td>
                                  <td>{item.host_name}</td>
                                  <td>
                                    {item.visit_data.visiting_date_time
                                      ? new Date(
                                          item.visit_data.visiting_date_time
                                        ).toLocaleDateString()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.visit_data.visiting_date_time
                                      ? new Date(
                                          item.visit_data.visiting_date_time
                                        ).toLocaleTimeString()
                                      : ""}
                                  </td>
                                  <td>{item.visit_data.purpose_of_visit}</td>
                                  <td>{item.visit_data.access_token}</td>
                                  <td>
                                    {item.visit_data.sign_in_time
                                      ? new Date(
                                          item.visit_data.sign_in_time
                                        ).toLocaleTimeString()
                                      : ""}
                                  </td>
                                  <td>
                                    {item.visit_data.sign_out_time
                                      ? new Date(
                                          item.visit_data.sign_out_time
                                        ).toLocaleTimeString()
                                      : ""}
                                  </td>
                                  <td>
                                    <div
                                      data-bs-toggle={
                                        (currentUser.role === "user" &&
                                          item.visit_data?.status.toLowerCase() ===
                                            "pending") ||
                                        (currentUser.role === "admin" &&
                                          item.visit_data?.status.toLowerCase() ===
                                            "pending") ||
                                        (currentUser.role === "facility" &&
                                          item.visit_data?.status.toLowerCase() ===
                                            "facility approval pending")
                                          ? "dropdown"
                                          : ""
                                      }
                                      aria-expanded="false"
                                      className={
                                        "badge fw-bolder dropdown-toggle"
                                      }
                                      /* className={`badge fw-bolder ${item.visit_data.status === "pending"
                                      ? "dropdown-toggle badge-light-warning"
                                      : ""
                                      } ${item.visit_data.status === "approved"
                                        ? "dropdown-toggle badge-light-success"
                                        : ""
                                      } ${item.visit_data.status === "rejected"
                                        ? "badge-light-danger"
                                        : ""
                                      }`} */
                                      style={{
                                        backgroundColor:
                                          item.visit_data.meta
                                            .status_color_code, // Set the background color from the API response
                                      }}
                                    >
                                      {item.visit_data.status === "pending"
                                        ? "Pending"
                                        : item.visit_data.status === "approved"
                                        ? "Approved"
                                        : item.visit_data.status}
                                    </div>
                                    {currentUser.role === "user" &&
                                      item.visit_data?.status.toLowerCase() ===
                                        "pending" && (
                                        <React.Fragment>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, true)
                                                }
                                              >
                                                Approve
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, false)
                                                }
                                              >
                                                Reject
                                              </a>
                                            </li>
                                          </ul>
                                        </React.Fragment>
                                      )}
                                    {currentUser.role === "facility" &&
                                      item.visit_data?.status.toLowerCase() ===
                                        "facility approval pending" && (
                                        <React.Fragment>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, true)
                                                }
                                              >
                                                Approve
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, false)
                                                }
                                              >
                                                Reject
                                              </a>
                                            </li>
                                          </ul>
                                        </React.Fragment>
                                      )}
                                    {currentUser.role === "admin" &&
                                      item.visit_data?.status.toLowerCase() ===
                                        "pending" && (
                                        <React.Fragment>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, true)
                                                }
                                              >
                                                Approve
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  approveRejectUser(item, false)
                                                }
                                              >
                                                Reject
                                              </a>
                                            </li>
                                          </ul>
                                        </React.Fragment>
                                      )}
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-light btn-active-light-primary btn-sm"
                                      data-kt-menu-trigger="click"
                                      data-kt-menu-placement="bottom-end"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="true"
                                      id="dropdownMenuButton4"
                                    >
                                      Action
                                      <i className="ki-duotone ki-down fs-5 ms-1"></i>
                                    </a>

                                    <div
                                      className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                                      data-kt-menu="flase"
                                      aria-labelledby="dropdownMenuButton4"
                                    >
                                      <div className="menu-item px-3">
                                        <a
                                          className="menu-link px-3"
                                          data-bs-toggle="modal"
                                          data-bs-target="#visitorInfoModal"
                                          onClick={() => {
                                            setModalData(true);
                                            setSelectedItem(item);
                                          }}
                                        >
                                          View
                                        </a>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="row mt-2">
                      <div className="col-1">
                        <select
                          name="records_per_age"
                          className="form-select form-select-lg form-select-solid w-auto"
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setItemsPerPage(value);
                            setTotalPages(
                              Math.ceil(visitorDataList.length / value)
                            );
                          }}
                          value={itemsPerPage}
                        >
                          {new Array(5).fill(null).map((i, index) => {
                            const value = 10 * (index + 1);
                            return (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-11">
                        {
                          <ResponsivePagination
                            total={totalPages}
                            current={currentPage}
                            onPageChange={(page) => handlePageChange(page)}
                          />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {activeView === "access" && (
                <div className="row">
                  <h2 className="fw-bolder my-2">Usergroup Access</h2>
                  <div className="col-12 mb-7">
                    <Select
                      isMulti
                      name="usergroup"
                      options={userGroupList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(selectedOptions) => {
                        const selectedIds = selectedOptions.map(
                          (option: any) => option.id
                        );
                        setuserGroupAccess(selectedIds);
                      }}
                    />
                    <div className="text-end mt-7">
                      <button
                        className="btn btn-primary"
                        style={{ marginLeft: "12px" }}
                        onClick={(e) => {
                          saveUserGroupAccess();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={divRef}
        className="modal fade"
        id="visitorInfoModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                {viewModal ? "View Info" : showAddVisitModal && ""}
              </h5>
              <button
                type="button"
                id="modalCloseBtn"
                className="btn-close"
                onClick={(e) => {
                  // setSelectedItem(null);
                  setModalData(false);
                  setShowAddVisitModal(false);

                  removeClass();
                }}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {viewModal ? (
                <VisitorInfo
                  visitorInfo={selectedItem}
                  modalCallBack={() => {
                    setSelectedItem(null);
                    setModalData(false);
                  }}
                />
              ) : (
                showAddVisitModal && (
                  <AddVisitorInfo
                    model_ref={divRef}
                    modalCallBack={() => {
                      setShowAddVisitModal(false);
                      document.getElementById("modalCloseBtn")?.click();
                    }}
                  />
                )
              )}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </>
  );
}
