/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ResponsivePagination from "react-responsive-pagination";
import { useDebouncedCallback } from "../../../utils";
import { getIaqDataByFloorId, getIaqDataByZoneId } from "./request";
import Select from "react-select";
import { getFloorList } from "../../apps/pantry-management/requests";
import { getZonesByFloorId } from "../../pod/components/requests";
import { KTIcon } from "../../../../sdb/helpers";
import { DeviceMapView } from "./mapView";

export function DeviceData() {
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const startIndex = (currentPage ? currentPage - 1 : 0) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const dataSet = dataList.slice(startIndex, endIndex);
  const [searchText, setSearchText] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [floorInfo, setFloorInfo] = useState(null);
  const [zoneInfo, setZoneInfo] = useState(null);
  const [floorID, setFloorID] = useState<string | null>(
    localStorage.getItem("floorID")
  );
  const [zoneID, setZoneID] = useState<string | null>(
    localStorage.getItem("zoneID")
  );
  const [viewModal, setModalData] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const deviceMapViewRef: any = useRef(null);
  const [records, setRecords] = useState([]);


  
  const onSearch = useDebouncedCallback(() => {
    let newDataList = [...filterDataList];
    if (newDataList.length > 0) {
      if (searchText) {
        const iText = searchText.toLowerCase();
        newDataList = newDataList.filter((x: any) => {
          const status = getStatusFromAQI(x.meta.aqi); 
          return (
            x?.identifier?.toLowerCase().includes(iText) ||
            String(x?.meta?.aqi).toLowerCase().includes(iText) || 
            String(x?.data?.data?.humidity).toLowerCase().includes(iText) || 
            String(x?.data?.data?.temperature).toLowerCase().includes(iText) || 
            status.toLowerCase().includes(iText) 
          );
        });
        
        
      }
  
      setDataList(newDataList);
      setTotalPages(Math.ceil(newDataList.length / itemsPerPage));
    }
  }, 500);
  
  const getStatusFromAQI = (aqi: number) => {
    if (aqi <= 50) {
      return "good";
    } else if (aqi >= 50 && aqi <= 100) {
      return "moderate";
    } else if (aqi >= 150 && aqi <= 200) {
      return "unhealthy if sensitive";
    } else if (aqi >= 200 && aqi <= 300) {
      return "unhealthy";
    } else {
      return "Funhe";
    }
  };
  
  
  const getFloors = async () => {
    try {
      const { data } = await getFloorList();
      if (data) {
        data.forEach((item: any) => {
          
          item["value"] = item._id;
          item["label"] = item.name;
        });
        setFloorList(data);
        
        
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const getZonesList = async () => {
    const id = localStorage.getItem("floorID");
    try {
      const { data } = await getZonesByFloorId(id);
      if (data && data.length > 0) { // Check if data exists and is not empty
        const mappedData = data.map((item) => ({
          value: item._id,
          label: item.name
        }));
        setRecords(mappedData);
        console.log(mappedData);
      } else {
        console.log("No zones found for the given floor ID");
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  

  const getDataList = async (floor_Id: any) => {
    localStorage.setItem("floorID", floor_Id);
    try {
      const { data } = await getIaqDataByFloorId(floor_Id);
      if (data) {
        setDataList(data);
        setFilterDataList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        
      }
      

    } catch (err) {
      console.log(err, "err");
    }
  };

  const getDataListZone = async (zone_id: any) => {
    localStorage.setItem("zoneID", zone_id);
    try {
      const { data } = await getIaqDataByZoneId("6628ec6b1e23219ea7dda538");
      if (data) {
        setDataList(data);
        setFilterDataList(data);
        setTotalPages(Math.ceil(data.length / itemsPerPage));
        console.log(data);
      }
      
      }
      
      catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    getFloors();
    getZonesList();
    onSearch();
  }, [searchText]);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    let floorIntervalId;

    if (floorID) {
      floorIntervalId = setInterval(() => {
        getDataList(floorID);
      }, 30000);
    }

    // Clear the floorID interval when the component unmounts or when floorID changes
    return () => clearInterval(floorIntervalId);
  }, [floorID, localStorage.getItem("floorID")]);

  /* useEffect(() => {
    let zoneIntervalId;

    if (zoneID) {
      zoneIntervalId = setInterval(() => {
        getDataListZone(zoneID);
      }, 30000);
    }

    // Clear the zoneID interval when the component unmounts or when zoneID changes
    return () => clearInterval(zoneIntervalId);
  }, [zoneID]); */

  /*   useEffect(() => {
    if (floorID) {
      const intervalId = setInterval(() => {
        getDataList(floorID);
      }, 30000);

      // Clear the interval when the component unmounts or when the floorID changes
      return () => clearInterval(intervalId);
    }
  }, [floorID]);

  useEffect(() => {
    if (zoneID) {
      const intervalId = setInterval(() => {
        getDataListZone(zoneID);
      }, 30000);

      // Clear the interval when the component unmounts or when the floorID changes
      return () => clearInterval(intervalId);
    }
  }, [zoneID]); */
  useEffect(() => {
    // This runs once when the component mounts
    $('#viewData').modal({
      backdrop: false,
      keyboard: false
    });
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts
  
  return (
    <div className="card card-flush my-5">
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
            <input
              type="text"
              value={searchText}
              data-kt-ecommerce-product-filter="search"
              className="form-control form-control-solid w-250px ps-12"
              placeholder="Search"
              onChange={(e) => {
                const value = e.target.value;
                setSearchText(value);
                onSearch(); 
              }}
              
            />
          </div>
        </div>

        <div className="card-toolbar">
          <div
            className="d-flex justify-content-start pe-4"
            style={{
              width: "200px",
            }}
          >
            {viewMode === "list" && (
              <a
                className="c-p btn btn-icon btn-light-primary me-4"
                onClick={() => {
                  setViewMode("map");
                  setTimeout(() =>
                    deviceMapViewRef.current.updateFloorInfo(floorInfo)
                  );
                }}
              >
                <KTIcon iconName="geolocation" className="fs-1 " />
              </a>
            )}
            {viewMode === "map" && (
              <a
                className="c-p btn btn-icon btn-light-primary me-4"
                onClick={() => {
                  setViewMode("list");
                }}
              >
                <KTIcon iconName="document" className="fs-1" />
              </a>
            )}
            <Select
              name="is_active"
              options={floorList}
              className="basic-multi-select floor"
              placeholder="Select Floor"
              classNamePrefix="select"
              onChange={(e: any) => {
                let floorInfo: any = null;
                if (e) {
                  const selectedFloorId = e._id;
                  setFloorID(selectedFloorId);
                  getDataList(selectedFloorId);
                  floorInfo = floorList.find(
                    (x: any) => x._id == selectedFloorId
                  );
                  console.log(getDataList);
                  
                } else {
                  setFloorID(null);
                  setDataList([]);
                }
                setFloorInfo(floorInfo);
              }}
            />
          </div>
          <div>
            <Select
              name="is_active"
              options={records}
              className="basic-multi-select zone mb-2"
              placeholder="Select Zone"
              classNamePrefix="select"
              onChange={(e: any) => {
                let zoneInfo: any = null;
                if (e) {
                  const selectedZoneId = e._id;
                  setZoneID(selectedZoneId);
                  getDataListZone(selectedZoneId);
                  zoneInfo = records.find((x: any) => x._id == selectedZoneId);
                } else {
                  setZoneID(null);
                  setDataList([]);
                }
                setZoneInfo(zoneInfo);
              }}
            />
          </div>
        </div>
      </div>

      <div className="card-body pt-0">
        {viewMode === "map" && <DeviceMapView ref={deviceMapViewRef} />}
        {viewMode === "list" && (
          <React.Fragment>
            <table
              className="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_ecommerce_products_table"
            >
              <thead>
                <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                  <th className="w-10px pe-2">
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        data-kt-check="true"
                        data-kt-check-target="#kt_ecommerce_products_table .form-check-input"
                        value="1"
                      />
                    </div>
                  </th>
                  <th className="min-w-150px">Identifier</th>
                  <th className="min-w-150px">Humidity</th>
                  <th className="min-w-150px">Temperature</th>
                  <th className="min-w-150px">Iaq</th>
                  <th className="min-w-150px">Status</th>
                  <th className="text-end min-w-70px">Actions</th>
                </tr>
              </thead>
              <tbody className="fw-semibold text-gray-600">
                {dataSet.map((item: any, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value="1"
                          />
                        </div>
                      </td>
                      <td>
                        <div className="ms-5">
                          <div className="text-gray-800 text-hover-primary fs-5 fw-bold mb-1">
                            {item.identifier}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-muted fs-7 fw-bold">
                            {item.data.data.humidity}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-muted fs-7 fw-bold">
                            {item.data.data.temperature}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div className="text-muted fs-7 fw-bold">
                            {item.meta.aqi}
                          </div>
                        </div>
                      </td>
                      <td className="pe-0">
                        <div
                          className={
                            item.meta.aqi <= 50
                              ? "badge badge-light-success fw-bolder"
                              : item.meta.aqi >= 50 && item.meta.aqi <= 100
                              ? "badge badge-light-warning fw-bolder"
                              : item.meta.aqi >= 100 && item.meta.aqi <= 150
                              ? "badge orange fw-bolder"
                              : item.meta.aqi >= 150 && item.meta.aqi <= 200
                              ? "badge badge-light-danger fw-bolder"
                              : item.meta.aqi >= 200 && item.meta.aqi <= 300
                              ? "badge grape fw-bolder"
                              : "badge dark-grape fw-bolder"
                          }
                        >
                          {item.meta.aqi <= 50
                            ? "Good"
                            : item.meta.aqi >= 50 && item.meta.aqi <= 100
                            ? "Moderate"
                            : item.meta.aqi >= 150 && item.meta.aqi <= 200
                            ? "Unhealthy if sensitive"
                            : item.meta.aqi >= 200 && item.meta.aqi <= 300
                            ? "Unhealthy"
                            : "Hazardous"}
                          {/* <span className="ps-2">{item.meta.aqi}</span> */}
                        </div>
                      </td>
                      <td className="text-end">
                        <a
                          className="btn btn-light-primary btn-sm c-p me-3"
                          data-bs-toggle="modal"
                          data-bs-target="#viewData"
                          onClick={() => {
                            setModalData(true);
                            setSelectedItem(item);
                          }}
                        >
                          <KTIcon iconName="eye" className="fs-3" />
                        </a>
                        {/* 
                    <div
                      className="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
                      data-kt-menu="flase"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div className="menu-item px-3 dropdown-item">
                        <a
                          className="menu-link px-3"
                          data-bs-toggle="modal"
                          data-bs-target="#viewData"
                          onClick={() => {
                            setModalData(true);
                            setSelectedItem(item);
                          }}
                        >
                          View
                        </a>
                      </div>
                    </div> */}
                      </td>
                    </tr>
                  );
                })}
                {dataList.length === 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No Records
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="row mt-2">
              <div className="col-1">
                <select
                  name="records_per_age"
                  className="form-select form-select-lg form-select-solid w-auto"
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    setItemsPerPage(value);
                    setTotalPages(Math.ceil(dataList.length / value));
                  }}
                  value={itemsPerPage}
                >
                  {new Array(5).fill(null).map((i, index) => {
                    const value = 5 * (index + 1);
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-11">
                {
                  <ResponsivePagination
                    total={totalPages}
                    current={currentPage}
                    onPageChange={(page) => handlePageChange(page)}
                  />
                }
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
      <div
        className="modal"
        id="viewData"
        data-bs-backdrop="false"
        // data-bs-keyboard="false"
        // aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
        
      >
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" >
          {viewModal && (
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="staticBackdropLabel">
                  View Data
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  id="modalCloseBtn"
                  onClick={(e) => {
                    setModalData(false);
                    setSelectedItem({});
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  <label className="col-sm-4 fw-bold col-form-label">
                    Identifier
                  </label>
                  <div className="col-sm-8">
                    <label className="col-form-label">
                      {selectedItem.identifier}
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4 fw-bold col-form-label">
                    Humidity
                  </div>
                  <div className="col-sm-8">
                    <label className="col-form-label">
                      {`${selectedItem.data.data.humidity} ${selectedItem.data.units.humidity}`}
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4 fw-bold col-form-label">
                    Temperature
                  </div>
                  <div className="col-sm-8">
                    <label className="col-form-label">
                      {`${selectedItem.data.data.temperature} ${selectedItem.data.units.temperature}`}
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4 fw-bold col-form-label">Status</div>
                  <div className="col-sm-8">
                    <label
                      className={
                        selectedItem.meta.aqi <= 50
                          ? "badge badge-light-success fw-bolder col-form-label"
                          : selectedItem.meta.aqi >= 50 &&
                            selectedItem.meta.aqi <= 100
                          ? "badge badge-light-warning fw-bolder col-form-label"
                          : selectedItem.meta.aqi >= 100 &&
                            selectedItem.meta.aqi <= 150
                          ? "badge orange fw-bolder col-form-label"
                          : selectedItem.meta.aqi >= 150 &&
                            selectedItem.meta.aqi <= 200
                          ? "badge badge-light-danger fw-bolder col-form-label"
                          : selectedItem.meta.aqi >= 200 &&
                            selectedItem.meta.aqi <= 300
                          ? "badge grape fw-bolder col-form-label"
                          : "badge dark-grape fw-bolder col-form-label"
                      }
                    >
                      {selectedItem.meta.aqi <= 50
                        ? "Good"
                        : selectedItem.meta.aqi >= 50 &&
                          selectedItem.meta.aqi <= 100
                        ? "Moderate"
                        : selectedItem.meta.aqi >= 150 &&
                          selectedItem.meta.aqi <= 200
                        ? "Unhealthy if sensitive"
                        : selectedItem.meta.aqi >= 200 &&
                          selectedItem.meta.aqi <= 300
                        ? "Unhealthy"
                        : "Hazardous"}
                    </label>
                    <span className="ps-2">({selectedItem.meta.aqi})</span>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4 fw-bold col-form-label">Dudid</div>
                  <div className="col-sm-8">
                    <label className="col-form-label">
                      {selectedItem.data.dudid}
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4 fw-bold col-form-label">Dsn</div>
                  <div className="col-sm-8">
                    <label className="col-form-label">
                      {selectedItem.data.dsn}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
