import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth";
import { addpostgraphapi, updategraphapi } from "./requests";
import { getCustomers } from "../../../../app/pages/Tenants/requests";

import Swal from "sweetalert2";
import Select from "react-select";
import clsx from "clsx";

import Ajv from "ajv";
import { ValueScope } from "ajv/dist/compile/codegen";

// json validator START
const ajv = new Ajv();

const schema = {
  type: "object",
  properties: {
    client_id: { type: "string" },
    client_secret: { type: "string" },
    tenant_id: { type: "string" },
    user_id: { type: "string" },
    send_email: { type: "string" },
  },
  required: [
    "client_id",
    "client_secret",
    "tenant_id",
    "user_id",
    "send_email",
  ],
};
const API_URL = process.env.REACT_APP_API_URL;

export function MicrosoftGRAPHAPIADD() {
  const history = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();
  const categoryInfo: any = location.state;
  const [customerlist, setcustomerlist] = useState([]);

  const editUserSchema = Yup.object().shape({
    is_active: Yup.object({
      value: Yup.string().required("Status is required"),
    }),

    settingvalue: Yup.string().required("Setting is required"),
    name: Yup.object({
      value: Yup.string().required("Name is required"),
    }),
  });

  const [categoryData] = useState<any>({
    name: categoryInfo ? categoryInfo.name : "",
    setting: categoryInfo ? categoryInfo.setting : "",
    is_active:
      categoryInfo?.is_active === false
        ? { value: "0", label: "Inactive" }
        : { value: "1", label: "Active" },
    customer_id: currentUser._id,
  });

  const formik: any = useFormik({
    validationSchema: editUserSchema,

    initialValues: categoryData,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const isActive = values.is_active.value === "0" ? "false" : "true";
        const payload = {
          customer_id: values.name.value, // Use selected customer's ID
          setting: JSON.parse(values.settingvalue),
          name: values.name.label,
          is_active: isActive,
        };
        console.log(payload);

        if (categoryInfo) {
          await updategraphapi(payload, categoryInfo._id);
          Swal.fire(
            "Mircosoft Graph API",
            "Mircosoft Graph API has been updated successfully!",
            "success"
          );
          history("/microsoftgraphapi");
        } else {
          await addpostgraphapi(payload);
          Swal.fire(
            "Mircosoft Graph API",
            "Mircosoft Graph API has been saved successfully!",
            "success"
          );
          history("/microsoftgraphapi");
        }
      }catch (error) {
        if ((error as any).response) { // Type assertion to 'any'
          Swal.fire("Microsoft GraphAPI", (error as any).response.data.detail, "error");
        } else {
          Swal.fire("Microsoft GraphAPI", "An error occurred", "error");
        }
      } finally {
        setSubmitting(false); // Change back to false here
      }
    },
  });

  // Inside the return statement

  const customers = async (customerId: number) => {
    try {
      const { data } = await getCustomers();
      if (data) {
        data.forEach((element) => {
          element["value"] = element._id;
          element["label"] = element.name;
        });
        setcustomerlist(data);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (currentUser) {
      customers(currentUser?._id);
    }
  }, []);

  const [input, setInput] = useState("");
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const handleInputChange = (e) => {
    const jsonInput = e.target.value;
    setInput(jsonInput);
    validateJson(jsonInput);
    formik.setFieldValue("settingvalue", jsonInput); 

  };

  const validateJson = (jsonData) => {
    try {
      const parsedJson = JSON.parse(jsonData);
      const validate = ajv.compile(schema);
      const valid = validate(parsedJson);
      setIsValid(valid);
    } catch (error) {
      setIsValid(false);
    }
  };
  const clearInput = () => {
    setInput("");
    setIsValid(null);
    formik.setFieldValue("settingvalue", ""); 

  };
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid">
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-flex flex-stack"
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Microsoft GraphAPI
              </h1>

              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history("/integrations");
                    }}
                    className="text-muted text-hover-primary"
                  >
                    Integrations
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-muted">
                  {" "}
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history("/microsoftgraphapi");
                    }}
                    className="text-muted text-hover-primary"
                  >
                    Microsoft GraphAPI
                  </a>
                </li>

                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <li className="breadcrumb-item text-muted">
                  {categoryInfo ? "Edit" : "Add"}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="kt_app_content" className="app-content flex-column-fluid">
          <div
            id="kt_app_content_container"
            className="app-container container-xxl"
          >
            <form
              id="kt_ecommerce_add_product_form"
              className="form d-flex flex-column flex-lg-row mb-10"
              onSubmit={formik.handleSubmit}
            >
              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      {/* <h2> Microsoft</h2> */}
                    </div>
                  </div>
                  <div className="card-body text-center pt-0">
                    <div
                      className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                      data-kt-image-input="true"
                    >
                      <img
                        src="media/microsoft.png"
                        className="card-img-top"
                        alt="imgs"
                      />
                    </div>
                  </div>
                </div>
                <div className="card card-flush py-4">
                  <div className="card-header">
                    <div className="card-title">
                      <h2>Status</h2>
                    </div>
                    <div className="card-toolbar">
                      <div
                        className={
                          formik.values.is_active.value === "0"
                            ? "rounded-circle bg-danger w-15px h-15px"
                            : "rounded-circle bg-success w-15px h-15px"
                        }
                        id="kt_ecommerce_add_product_status"
                      ></div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <Select
                      {...formik.getFieldProps("is_active")}
                      name="is_active"
                      options={[
                        { value: "1", label: "Active" },
                        { value: "0", label: "Inactive" },
                      ]}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        formik.setFieldValue("is_active", e);
                      }}
                    />
                    <div className="text-muted fs-7"></div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                  <div className="card card-flush py-4">
                    <div className="card-header">
                      <div className="card-title">
                        <h2>Integration Setup</h2>
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <div className="mb-10 fv-row">
                        <label className="required form-label">Name</label>
                        <Select
                          {...formik.getFieldProps("name")}
                          name="name"
                          options={customerlist.map((item: any) => ({
                            value: item._id, // Assuming customer ID is stored in _id field
                            label: item.name,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            formik.setFieldValue("name", e);
                          }}
                          onFocus={(e) => {
                            formik.setFieldTouched("name", true);
                          }}
                        />

                        {formik.touched.name && formik.errors.name && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              <span role="alert">
                                {formik.errors.name.value}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div style={{ display: "grid" }}>
                        <label className="form-label required">
                          SAML Setting
                        </label>
                        <textarea
                          name="settingvalue"
                          {...formik.getFieldProps("settingvalue")}
                          className={clsx(
                            "form-control form-control-solid mb-3 mb-lg-0",
                            {
                              "is-invalid":
                                formik.touched?.settingvalue &&
                                formik.errors?.settingvalue,
                            }
                          )}
                          autoComplete="off"
                          value={input}
                          onChange={(e) => {
                            formik.handleChange(e);
                            handleInputChange(e); // Call handleInputChange to update input state
                          }}
                          onBlur={formik.handleBlur}
                          rows={10}
                          cols={50}
                          placeholder="Enter JSON here"
                        />

                        {/* <textarea
              placeholder="Description"
              rows={11}
              {...formik.getFieldProps("description")}
              className="form-control mb-2"
              name="description"
              autoComplete="off"
            /> */}
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={clearInput}
                        >
                          Clear
                        </button>

                        {isValid === true && (
                          <div
                            className="alert alert-success mt-2"
                            role="alert"
                          >
                            <p>JSON is valid according to the schema!</p>
                          </div>
                        )}
                        {isValid === false && (
                          <div className="alert alert-danger mt-2" role="alert">
                            JSON is not valid according to the schema.
                          </div>
                        )}
                        {isValid === null && <p></p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      history("/microsoftgraphapi");
                    }}
                    id="kt_ecommerce_add_product_cancel"
                    className="btn btn-light me-5"
                  >
                    Cancel
                  </a>

                  <button
                    type="submit"
                    id="kt_ecommerce_add_product_submit"
                    className="btn btn-primary"
                    disabled={
                      formik.isSubmitting ||
                      !formik.isValid ||!isValid === true
                    }
                  >
                    <span className="indicator-label"> Save </span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
