import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js/auto';

type CustomChartOptions = ChartOptions<'line'> & {
  extra?: {
    elements?: {
      line?: {
        tension?: number;
      };
    };
  };
};

const options: CustomChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      min: 50,
      max: 150,
      ticks: {
        stepSize: 25,
        callback: function (value) {
          return value;
        },
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
    },
  },
  extra: {
    elements: {
      line: {
        tension: 0,
      },
    },
  },
};

const ResponsiveChart: React.FC<{ chartData: any }> = ({ chartData }) => {
  const [chartHeight, setChartHeight] = useState<number | string>('auto');

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 600) {
        setChartHeight('auto');
      } else if (width <= 992) {
        setChartHeight(300);
      } else {
        setChartHeight(400);
      }
    };

    handleResize(); // Call on initial render

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!chartData) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ height: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
      <div style={{ height: `${chartHeight}px`, overflowX: 'auto', overflowY: 'auto' }}>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default ResponsiveChart;
