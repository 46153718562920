import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { getIaqDataByFloorId, getIaqDataByZoneId } from "./request";
import "./Deviceaction.css";
import ResponsiveChart from "./chart";

import Stack from "@mui/material/Stack";
import Button_2 from "@mui/material/Button";

export function DeviceAction() {
  const [dataList, setDataList] = useState([]);
  const [filterDataList, setFilterDataList] = useState([]);
  const [floorID, setFloorID] = useState<string | null>(
    localStorage.getItem("floorID")
  );
  const [showTimeFilters, setShowTimeFilters] = useState(false);

  const content = (
    <div>
      <p>This is the popup message!</p>
    </div>
  );

  const getDataList = async (floor_Id: any) => {
    localStorage.setItem("floorID", floor_Id);
    try {
      const { data } = await getIaqDataByFloorId(floor_Id);
      if (data) {
        setDataList(data);
        setFilterDataList(data);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  useEffect(() => {
    let floorIntervalId;

    if (floorID) {
      floorIntervalId = setInterval(() => {
        getDataList(floorID);
      }, 30000);
    }

    // Clear the floorID interval when the component unmounts or when floorID changes
    return () => clearInterval(floorIntervalId);
  }, [floorID, localStorage.getItem("floorID")]);

  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      fill: boolean;
      backgroundColor: string;
      borderColor: string;
    }[];
  } | null>(null);

  useEffect(() => {
    // Set default chart data to data1 on initial load
    if (chartData === null) {
      console.log("Setting chartData to data1");
      setChartData(data1);
    }
  }, [chartData]);

  const handleButtonClick = (data) => {
    setChartData(data);
  };
  const data1 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Overall Index",
        data: [50, 75, 150, 50, 102, 150, 100],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#84DE02",
      },
    ],
  };

  const data2 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "CO₂",
        data: [100, 125, 143, 96, 66, 87, 67],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FF5733",
      },
    ],
  };

  const data3 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Humidity",
        data: [80, 123, 110, 114, 88, 122, 65],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#7366FF",
      },
    ],
  };

  const data4 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "PM10",
        data: [56, 66, 77, 78, 99, 100, 56],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FFC300",
      },
    ],
  };

  const data5 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "PM2.5",
        data: [50, 79, 145, 78, 56, 76, 111],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FF5733",
      },
    ],
  };

  const data6 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "REST Viral Index",
        data: [80, 85, 90, 85, 88, 90, 85],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#34EB7E",
      },
    ],
  };

  const data7 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "Temperature",
        data: [67, 56, 98, 51, 66, 111, 143],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#FFC300",
      },
    ],
  };

  const data8 = {
    labels: [
      "11.30AM",
      "12.30AM",
      "1.30AM",
      "2.30AM",
      "3.30AM",
      "4.30AM",
      "5.30AM",
    ],
    datasets: [
      {
        label: "TVOC",
        data: [77, 65, 70, 97 , 87, 111, 123],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "#7366FF",
      },
    ],
  };

  return (
    <>
      <div
        className="d-flex flex-wrap flex-stack mb-6 actioncontainer"
        style={{ display: "none" }}
      >
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3  py-lg-0">
            <div
              id="kt_app_toolbar_container"
              className="app-container-fluid d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                  IAQ Management
                </h1>

                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-0">
                  <li className="breadcrumb-item text-muted">
                    <a
                      href="/modules/device-management"
                      className="text-muted text-hover-primary"
                    >
                      Back to Devices{" "}
                    </a>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-400 w-5px h-2px"></span>
                  </li>

                  <li className="breadcrumb-item text-muted">IAQ Management</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div id="kt_app_content_container" className="app-container-fluid">
              <div className="d-flex h-95px mt-5 top-container">
                <div className="left-container">
                  <h6 className="text-muted text">Device Name</h6>
                  <h1>1F-IAQ-1</h1>
                </div>

                <div className="Right-container">
                  {/* <Popover content={content} title="Title" trigger="click">
                    <Button icon={<EllipsisOutlined />} />
                  </Popover> */}
                </div>
              </div>
            </div>

            <div className="container">
              <div className="card-container">
                <Card hoverable={false}>
                  <b>Overall Index</b>
                </Card>
                <Card hoverable={false}>
                  <b>CO₂</b>
                </Card>
                <Card hoverable={false}>
                  <b>PM10</b>
                </Card>
                <Card hoverable={false}>
                  <b>PM2.5</b>
                </Card>
                <Card hoverable={false}>
                  <b>TVOC</b>
                </Card>
                <Card hoverable={false}>
                  <b>REST Viral Index</b>
                </Card>
                <Card hoverable={false}>
                  <b>Temperature</b>
                </Card>
              </div>

              <Row gutter={16} className="middle-container-2 mt-5">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 8 }}
                >
                  <div>
                    <h6 className="text-muted text">Last Updated 4:00PM</h6>
                    <h1>Air Quality History</h1>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 16 }}
                >
                  <div className="d-flex flex-column align-items-start">
                    <Button
                      type="primary"
                      onClick={() => setShowTimeFilters(!showTimeFilters)}
                    >
                      Last
                    </Button>
                    {showTimeFilters && (
                    <div className="time-filter-buttons">
                    <div className="top-buttons">
                      <Button type="dashed">Last Hour</Button>
                      <Button type="dashed">Last 12 Hours</Button>
                      <Button type="dashed">Last 24 Hours</Button>
                    </div>
                    <div className="bottom-buttons">
                      <Button type="dashed">Last 7 days</Button>
                      <Button type="dashed">Last 30 days</Button>
                      <Button type="dashed">Custom</Button>
                    </div>
                  </div>
                  
                    )}
                  </div>
                </Col>
              </Row>

              <div className="endcontainer">
                <ResponsiveChart chartData={chartData} />
                <div className="buttongrp">
                  <Button_2
                    variant="outlined"
                    className="btn_1"
                    onClick={() => handleButtonClick(data1)}
                  >
                    Overall Index
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_2"
                    onClick={() => handleButtonClick(data2)}
                  >
                    CO₂
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_3"
                    onClick={() => handleButtonClick(data3)}
                  >
                    Humidity
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_4"
                    onClick={() => handleButtonClick(data4)}
                  >
                    PM10
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_5"
                    onClick={() => handleButtonClick(data5)}
                  >
                    PM2.5
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_6"
                    onClick={() => handleButtonClick(data6)}
                  >
                    REST Viral Index
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_7"
                    onClick={() => handleButtonClick(data7)}
                  >
                    Temperature
                  </Button_2>
                  <Button_2
                    variant="outlined"
                    className="btn_8"
                    onClick={() => handleButtonClick(data8)}
                  >
                    TVOC
                  </Button_2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeviceAction;
