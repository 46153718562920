import { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormik } from "formik";

const loginSchema = Yup.object().shape({
  comment: Yup.string().required("Comments is required"),
});

const initialValues = {
  comment: "",
};

export function FeedbackForm({ setModalData, setSelectedItem }) {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <div className="modal-content">
      <div className="modal-header border-0">
        <h2 className="modal-title" id="staticBackdropLabel">
          Feedback
        </h2>
        <button
          type="button"
          className="btn-close"
          onClick={(e) => {
            setSelectedItem(null);
            setModalData(false);
          }}
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div className="modal-body">
        <form
          className="form w-100"
          onSubmit={formik.handleSubmit}
          noValidate
          id="kt_feedback_form"
        >
          {formik.status ? (
            <div className="mb-lg-15 alert alert-danger">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          ) : (
            ""
          )}

          <div className="fv-row mb-8">
            <label className="form-label fs-6 text-dark">
              Comments <span className="text-danger">*</span>
            </label>
            <textarea
              placeholder="Note"
              {...formik.getFieldProps("email")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid": formik.touched.comment && formik.errors.comment,
                },
                {
                  "is-valid": formik.touched.comment && !formik.errors.comment,
                }
              )}
              rows={8}
              name="comment"
              autoComplete="off"
            />
            {formik.touched.comment && formik.errors.comment && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.comment}</span>
                </div>
              </div>
            )}
          </div>

          {/* begin::Action */}
          <div className="d-flex justify-content-center my-5">
            <button
              type="button"
              className="btn btn-secondary me-5"
              onClick={(e) => {
                setModalData(false);
                setSelectedItem(null);
              }}
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-primary"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
