import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export function getPersonalization(id: any) {
  return axios.get<any>(`${API_URL}/personalisation/customer/${id}/`);
}

export function addPersonalization(data: any) {
  return axios.post<any>(`${API_URL}/personalisation/create/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function updatePersonalization(data: any, id: any) {
  return axios.put<any>(`${API_URL}/personalisation/update/${id}/`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
export function getLogs(id: any) {
  return axios.get<any>(`${API_URL}/logs/customer/${id}/`);
}
export function downloadLog(id: any) {
  return axios.get<any>(`${API_URL}/logs/customer/${id}/download`);
}
export function deleteLog(id: any) {
  return axios.delete<any>(`${API_URL}/logs/customer/${id}/`);
}
