import React, { useState, useEffect } from "react";
import { getEndpoints, addUsergroup, updateUsergroup } from "./requests";
import MultiSelect from "@kenshooui/react-multi-select";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";
import { PageTitle } from "../../../sdb/layout/core";

export function Addusergroup() {
  const [endpointList, setEndpointList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [newEndPoints, setNewEndPoints] = useState([]);
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const history = useNavigate();

  const location: any = useLocation();
  let customerData = location.state;

  const handleChange = (selectedItems: any) => {
    setSelectedItems(selectedItems);
    const modifiedData = selectedItems?.map((item: any) => ({
      route: item.route,
      method: item.method,
      name: item.name,
    }));
    setNewEndPoints(modifiedData);
  };

  const getEndpointsData = async () => {
    try {
      const { data } = await getEndpoints();
      if (data) {
        const newData = data.map((item: any) => ({
          id: item.route,
          label: item.tags + " | " + item.name,
          disabled: false,
          ...item,
        }));
        setEndpointList(newData);
      }
    } catch (err) {
      console.log(err, "err");
    }
  };

  const onSubmit = async (values: any) => {
    try {
      const newValues = {
        name: name,
        permission: [...values],
        description: description,
        customer_id: customerData.id,
        is_active: true,
      };
      await addUsergroup(newValues);
      setEndpointList([]);
      setSelectedItems([]);
      setDescription("");
      setName("");
      history(`/tenants/${customerData.id}/access`);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const onUpdate = async (values: any) => {
    try {
      const newValues = {
        name: name,
        permission: [...values],
        description: description,
        customer_id: customerData._id,
        is_active: true,
      };
      await updateUsergroup(newValues, customerData._id);
      setEndpointList([]);
      setSelectedItems([]);
      setDescription("");
      setName("");
      history(`/servicegroup`);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const setDescriptionValue = (value: any) => {
    setDescription(value);
  };
  const setNameValue = (value: any) => {
    setName(value);
  };

  useEffect(() => {
    if (customerData) {
      const newCustomerData = customerData?.permission?.map((item: any) => ({
        id: item.name,
        label: item.name,
        disabled: false,
        ...item,
      }));
      setSelectedItems(newCustomerData);
      setDescription(customerData.description);
      setName(customerData.name);
    }

    getEndpointsData();
  }, []);
  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-6">
        <PageTitle
        // SDB-6(Breadcrumbname changed cutomers--->Tenants Access--->User API Permissions</div>)
          breadcrumbs={[
            
            {
              title: "Service Group",
              // path:
              //   customerData.from == "add"
              //     ? `/tenants/${customerData.id}/access`
              //     : `/tenants/${customerData.cId}/access`,
              path:"/servicegroup",
              isSeparator: false,
              isActive: false,
            },
            {
              title: "",
              path: "",
              isSeparator: true,
              isActive: false,
            },
          ]}
        >
            {/* SDB-6(Name changed Add Access--->User API Permissions, Edit Access--->Edit User API Permissions</div>) */}

          {customerData.from == "add" ? "Add User API Permissions" : "Edit Service Group"}
        </PageTitle>
      </div>
      <div className="row">
        <div className="d-flex mb-4">
          <div className="col-6 me-4">
            <label className=" fw-bold fs-6 mb-2 required">Name</label>
            <input
              type="text"
              name="name"
              className={clsx("form-control form-control-solid mb-3 mb-lg-0", {
                "is-invalid": name === "",
              })}
              autoComplete="off"
              onChange={(e) => setNameValue(e.target.value)}
              value={name}
            />
          </div>
          <div className="col-6">
            <label className=" fw-bold fs-6 mb-2">Description</label>
            <textarea
              className="form-control form-control-solid mb-3"
              rows={3}
              data-kt-element="input"
              onChange={(e) => setDescriptionValue(e.target.value)}
              value={description}
            ></textarea>
          </div>
        </div>
        <MultiSelect
          items={endpointList}
          selectedItems={selectedItems}
          onChange={handleChange}
        />
        <div className="text-end mt-7">
          {customerData.from == "add" ? (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "12px" }}
              onClick={() => onSubmit(newEndPoints)}
              disabled={name === "" || newEndPoints.length === 0}
            >
              Save
            </button>
          ) : (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "12px" }}
              onClick={() => onUpdate(newEndPoints)}
              disabled={name === "" || selectedItems.length === 0}
            >
              Update
            </button>
          )}
        </div>
      </div>
    </>
  );
}
