import { useEffect, useState } from "react";
import { useAuth } from "./core/Auth";
import { useLocation, useNavigate } from "react-router";
import { getUserByToken } from "./core/_requests";

export function AuthLoading() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authToken = queryParams.get("token");
  const [loading, setLoading] = useState(false);
  const history = useNavigate();
  const { saveAuth, setCurrentUser } = useAuth();
  const loadUserInfo = async () => {
    const auth: any = {
      access_token: authToken,
      token_type: "bearer",
    };
    saveAuth(auth);
    getUserByToken(auth.access_token).then((r) => {
      const user = r.data;
      setCurrentUser(user);
      localStorage.setItem("userData", JSON.stringify(user));
      history("/dashboard");
    });
  };
  useEffect(() => {
    loadUserInfo();
  }, []);
  return (
    <div className="container text-center mt-6">
      {/* <h3>Auth Token: {authToken}</h3> */}
    </div>
  );
}
